import * as type from "../../actions/index.types";

export function societyReportReducer(
  state = {
    loading: false,
    societyEmailReport: [],
    societyParkingReport: [],
    societyDownloadHistory: [],
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_SOCIETY_EMAIL_REPORTS:
      return action?.data?.export == 1
        ? { ...state }
        : {
          ...state,
          loading: true,
          error: null,
        };
    case type.GET_SOCIETY_EMAIL_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        societyEmailReport: action.payload,
      };
    case type.GET_SOCIETY_EMAIL_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        downLoader: false,
        societyEmailReport: [],
      };
    case type.GET_MEMBER_LEDGER_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
        reportList: [],
      };
    case type.GET_MEMBER_LEDGER_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        reportList: action.payload,
      };
    case type.GET_MEMBER_LEDGER_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        reportList: [],
      };
    case type.CLEAR_MEMBER_LEDGER_REPORTS:
      return {
        ...state,
        loading: false,
        reportList: [],
      };
    case type.DOWNLOAD_NDC:
      return {
        ...state,
        NDCloading: true,
        NDCData: [],
      };
    case type.DOWNLOAD_NDC_SUCCESS:
      return {
        ...state,
        NDCloading: false,
        NDCData: action.payload,
      };
    case type.DOWNLOAD_NDC_FAILURE:
      return {
        ...state,
        NDCloading: false,
        NDCData: [],
      };

    case type.GET_SOCIETY_PARKING_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
        societyParkingReport: [],
      };
    case type.GET_SOCIETY_PARKING_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        societyParkingReport: action.payload,
      };
    case type.GET_SOCIETY_PARKING_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        societyParkingReport: [],
      };

    case type.GET_DOWNLOAD_UPLOAD_HISTORIES:
      return {
        ...state,
        loading: true,
        error: null,
        societyDownloadHistory: [],
      };
    case type.GET_DOWNLOAD_UPLOAD_HISTORIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        societyDownloadHistory: action.payload,
      };
    case type.GET_DOWNLOAD_UPLOAD_HISTORIES_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        societyDownloadHistory: [],
      };
    default:
      return state;
  }
}
