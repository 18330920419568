import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Layout/Layout";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Search from "../../../components/SearchBox/Search";
import ListHeader from "../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  PRINT_SOCIETY_PARKING_REPORT_ENDPOINT,
  SOCIETY_PARKING_REPORT_ENDPOINT,
} from "../../../Routing/routes";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import moment from "moment";
import ButtonG from "../../../components/Button/ButtonG";
import { geSocietyParkingReport } from "../../../redux/actions/ReportActions/SocietyReportActions";

import SocietyParkingReportsTable from "./SocietyParkingReportsTable";
import { getParkingTypeList } from "../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { DownloadExt, PrinterIcon } from "../../../assets";
import Button from "../../../components/Button/Button";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "70px" },
  { id: "flatDetails", label: "Flat Details", width: "150px" },
  { id: "memberName", label: "Member Name", sort: true, width: "150px    " },
  { id: "parkingType", label: "Parking Type", width: "150px" },
  { id: "qrCode", label: "QR Code", width: "100px" },
  { id: "stickerNo", label: "Parking Sticker No.", width: "150px" },
  { id: "parkingNo", label: "Parking No.", width: "150px" },
  { id: "vehicleType", label: "Vehicle Type", width: "150px" },
  { id: "vehicleNumber", label: "Vehicle Number", width: "150px" },
  { id: "vehicleBrand", label: "Vehicle Brand", width: "150px " },
  { id: "vehicleModel", label: "Vehicle Model", width: "150px " },
  { id: "year", label: "Year", width: "150px    " },
];
const vehicleTypeList = [
  { value: "2", label: "2 Wheelers" },
  {
    value: "4",
    label: "4 Wheelers",
  },
  { value: "Other", label: "Other" },
];
const SocietyParkingReports = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { societyParkingReport, loading, error } = useSelector(
    (state) => state.societyReportReducer
  );
  const { parkingTypeList } = useSelector(
    (state) => state.parkingManagementReducer
  );

  const { downLoader } = useSelector(
    (state) => state.downloadSocietyReportReducer
  );

  const navigate = useNavigate();

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("searchText")) {
      searchParams.delete("page");
      searchParams.delete("searchText");
      navigate({
        pathname: SOCIETY_PARKING_REPORT_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchText", text?.trim()],
        ])}`,
      });
    } else {
      searchParams.delete("searchText");
      navigate({
        pathname: SOCIETY_PARKING_REPORT_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };

  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectVehicleType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("vehicleType");

    navigate({
      pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["vehicleType", selectedItem.value],
      ])}`,
    });
  };

  const handleSelectParkingType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("parkingType");

    navigate({
      pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["parkingType", selectedItem.value],
      ])}`,
    });
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${SOCIETY_PARKING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(`${SOCIETY_PARKING_REPORT_ENDPOINT}`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getParkingTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  const getTableData = () => {
    dispatch(
      geSocietyParkingReport({
        societyId: loginData?.default_community?.community_id,
        flatId: searchParams.get("flatId") || "",
        wingId: searchParams.get("wingId") || "",
        parkingType: searchParams.get("parkingType") || "",
        vehicleType: searchParams.get("vehicleType") || "",
        searchText: searchParams.get("searchText") || "",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  };
  const handlePrintData = () => {
    navigate({
      pathname: `${PRINT_SOCIETY_PARKING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries()])}`,
    });
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Society Parking Report">
            <div className="flex gap-3 text-sm">
              {/* <div className="border-r border-[#CCC]"></div> */}

              <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg h-8"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
            </div>
          </ListHeader>
        </div>

        <div className="flex justify-between gap-2 py-1 my-1">
          <div className="flex gap-4">
            <Search
              height="8"
              width="32"
              placeholder="Search by Member Name"
              onclick={handleSearch}
              value={searchParams.get("searchText")}
            />
            <Dropdown2
              options={
                wingsData?.map((item) => ({
                  value: item?.wing_id,
                  label: item?.number,
                })) || []
              }
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectWing(selectedItem);
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find(
                    (item) =>
                      item.wing_id?.toString() === searchParams.get("wingId")
                  )?.number,
                }
              }
            />
            <Dropdown2
              options={
                (searchParams.get("wingId") &&
                  flatsData?.manage_flat_details?.map((item) => ({
                    value: item?.flat_id,
                    label: item?.flat_no,
                  }))) ||
                []
              }
              placeholder="Select Flat"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectFlat(selectedItem);
              }}
              value={
                searchParams.get("flatId") && {
                  value: searchParams.get("flatId"),
                  label: flatsData?.manage_flat_details?.find(
                    (item) =>
                      item.flat_id?.toString() === searchParams.get("flatId")
                  )?.flat_no,
                }
              }
            />
            <Dropdown2
              options={vehicleTypeList}
              placeholder="Select Vehicle Type"
              className="text-[11px] ms-4"
              width="175px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectVehicleType(selectedItem);
              }}
              value={
                searchParams.get("vehicleType") && {
                  value: searchParams.get("vehicleType"),
                  label: vehicleTypeList.find(
                    (item) => item?.value == searchParams.get("vehicleType")
                  )?.label,
                }
              }
            />
            <Dropdown2
              options={
                parkingTypeList?.map((item) => ({
                  value: item.parking_type_id,
                  label: item.title,
                })) || []
              }
              placeholder="Select Parking Type"
              className="text-[11px] ms-4"
              width="175px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectParkingType(selectedItem);
              }}
              value={
                searchParams.get("parkingType")
                  ? {
                      label: parkingTypeList.find(
                        (item) =>
                          item?.parking_type_id ==
                          searchParams.get("parkingType")
                      )?.title,
                      value: searchParams.get("parkingType"),
                    }
                  : null
              }
            />
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
        </div>
        <div>
          <SocietyParkingReportsTable
            loading={loading}
            data={societyParkingReport?.parking_list?.map((item, index) => ({
              sNo: (
                (societyParkingReport?.current_page_no - 1) *
                  societyParkingReport?.page_limit +
                1 +
                index
              )
                ?.toString()
                ?.padStart(2, "0"),
              id: item?.id,
              flatDetails: item.flat_details,
              memberName: item.member_name,
              parkingType: item.parking_type_title || "-",
              qrCode: item?.qr_image || "-",
              stickerNo: item?.parking_lot || "-",
              parkingNo: item?.parking_no || "-",
              vehicleType:
                vehicleTypeList.find(
                  (item2) => item2?.value == item?.vehicle_type
                )?.label || "-",
              vehicleNumber: item?.vehicle_number || "-",
              vehicleBrand: item?.vehicle_brand || "-",
              vehicleModel: item?.model || "-",
              year: item?.year || "-",
            }))}
            columns={headCells}
            checkbox={false}
            pagination={societyParkingReport?.parking_list?.length}
            totalCount={societyParkingReport?.total_record}
            totalPages={Number(societyParkingReport?.total_pages)}
            start={
              (societyParkingReport?.current_page_no - 1) *
                societyParkingReport?.page_limit +
              1
            }
            end={
              (societyParkingReport?.current_page_no - 1) *
                societyParkingReport?.page_limit +
              societyParkingReport?.parking_list?.length
            }
            currentPage={Number(societyParkingReport?.current_page_no)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          />
          {!societyParkingReport?.parking_list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SocietyParkingReports;
