import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  styled,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Button from "../../components/Button/Button";
import Layout from "../Layout/Layout";
import ListHeader from "../../components/ListHeader/ListHeader";
import { DeleteIcon, UploadEx } from "../../assets";
import { downloadSocietyStaffExcelSheet } from "../../redux/actions/SetupRecordsActions/StaffManagementActions/StaffPersonnelAction";
import { useSelector } from "react-redux";
import {
  downloadExcel,
  downloadFileFromURL,
} from "../../utils/helpers/downloadMedia";
import { useDispatch } from "react-redux";
import {
  clearBulkUploadError,
  downloadExcelFormat,
  uploadSocietyStaffList,
} from "../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import ButtonG from "../../components/Button/ButtonG";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { DropdownFM2 } from "../../components/Dropdown/dropdown2";
import { TextInput, TextInputFM } from "../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../components/DatePicker/DatePicker";
import moment from "moment";
import {
  bulkAddVehicleDetails,
  uploadVehicleDetails,
} from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { BulkAddVehicleValidation } from "../../validationSchema/setupRecordsSchema/occupants&TenantsSchema";
import { getWingsAndFlatDetails } from "../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getParkingTypeList } from "../../redux/actions/GateManagementActions/ParkingManagementAction";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #ffffff;
  }
  &:nth-of-type(odd):hover {
    background-color: #f4f5f8;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;
const headCells = [
  {
    id: "wingNo",
    label: "Wing No.",
    sort: false,
    width: "120px",
  },
  { id: "flatNo", label: "Flat No.", sort: false, width: "120px" },
  {
    id: "parkingStickerNo",
    label: "Parking Sticker No.",
    sort: false,
    width: "150px",
  },
  { id: "parkingType", label: "Parking Type", sort: false, width: "120px" },
  {
    id: "parkingNo",
    label: "Parking No",
    sort: false,
    width: "120px",
  },
  { id: "location", label: "Location", sort: false, width: "120px" },
  { id: "vehicleType", label: "Vehicle Type", sort: false, width: "120px" },
  { id: "vehicleNo", label: "Vehicle Number", sort: false, width: "120px" },
  { id: "vehicleBrand", label: "Vehicle Brand", sort: false, width: "120px" },
  { id: "vehicleModel", label: "Vehicle Model", sort: false, width: "120px" },
  { id: "year", label: "Year", sort: false, width: "120px" },
  { id: "action", label: "Action", width: "80px" },
];
const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "10px 6px 10px 0px",
    wordBreak: "break-word",
    verticalAlign: "top",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "12px",
    padding: "10px 6px 0px 0px",
    wordBreak: "break-word",
    cursor: "pointer",
    verticalAlign: "top",
  },
}));
const vehicleTypeList = [
  { value: "2", label: "2 Wheelers" },
  {
    value: "4",
    label: "4 Wheelers",
  },
  { value: "Other", label: "Other" },
];

const BulkUploadsSocietyParkingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [showvehicles, setShowvehicles] = useState(false);
  const [file, setFile] = useState();
  const inputFile = useRef(null);
  const { wingsData } = useSelector((state) => state.wingsReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { uploadLoader, uploadVehicleDetailsList, error } = useSelector(
    (state) => state.occupantInfoReducer
  );
  const { parkingTypeList } = useSelector(
    (state) => state.parkingManagementReducer
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDownloadExcel = () => {
    const url =
      "https://storage.googleapis.com/np-zg-dev-neo-bucket/society/4175/vehicleDetails/excel_1735644386613-vehicle.xlsx";
    downloadFileFromURL({ url, fileName: "VehicleDetails" });
  };

  const onUpload = () => {
    let data = {
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      file,
      sessionId: loginData?.session_id,
      onRequestEndCallback: () => {
        setFile(null);
        setShowvehicles(true);
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      },
    };
    dispatch(uploadVehicleDetails(data));
  };
  const handleSubmit = (values) => {
    dispatch(
      bulkAddVehicleDetails({
        ...values,
        onRequestEndCallback: () => {
          setShowvehicles(false);
        },
      })
    );
  };

  useEffect(() => {
    setShowvehicles(false);
    dispatch(getWingsAndFlatDetails());
    dispatch(
      getParkingTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  useEffect(() => {
    if (uploadVehicleDetailsList?.list?.length > 0) {
      formRef?.current?.setValues({
        vehicles: uploadVehicleDetailsList.list.map((item) => ({
          flatList: item?.flat_list?.length > 0 ? item.flat_list : [],
          flatId: item?.flat_id || "0",
          wingId: item?.wing_id || "0",
          stickerNo: item["Parking Sticker No."],
          parkingType: item["Parking Type"],
          parkingNo: item["Parking No."],
          location: item["Location"],
          vehicleType:
            vehicleTypeList.find((item2) => item2.label == item["Vehicle Type"])
              ?.value || "",
          vehicleNo: item["Vehicle No."],
          vehicleBrand: item["Vehicle Brand"],
          modal: item["Vehicle Model"],
          year: item["Year"],
          rfid: "",
        })),
        societyId: loginData?.default_community?.community_id,
        ipAddress,
        sessionId: loginData?.session_id,
      });
    }
  }, [uploadVehicleDetailsList?.list]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            vehicles: [],
          }}
          // enableReinitialize
          validationSchema={BulkAddVehicleValidation}
          innerRef={(f) => (formRef.current = f)}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="border-b border-[#CCC]">
                <ListHeader leftContent="Society Vehicle Details Excel Upload">
                  {!showvehicles ? (
                    <Button
                      label={
                        <span className="w-12 text-enter inline-block">
                          {uploadLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Upload"
                          )}
                        </span>
                      }
                      className="px-4 h-8 disabled:opacity-50"
                      disabled={!file}
                      onClick={onUpload}
                      type="button"
                    />
                  ) : (
                    <>
                      <ButtonG
                        label="Cancel"
                        onClick={() => {
                          setShowvehicles(false);
                        }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label={
                          <span className="w-12 text-enter inline-block">
                            {uploadLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                        className="px-4 h-8 "
                        type="submit"
                      />
                    </>
                  )}
                  {/* <div className="border-r border-[#CCC]"></div> */}
                </ListHeader>
              </div>
              {showvehicles ? (
                <Box
                  sx={{ width: "100%", fontSize: "13px" }}
                  className="h-full table-component"
                >
                  <TableContainer
                    className="border-t border-[#CCC]"
                    sx={{
                      minHeight:
                        values?.vehicles?.length > 0 ? "500px" : "none",
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {headCells?.map((column) => (
                            <TableCellStyled
                              size="small"
                              key={column.id + "heading"}
                              align={column.numeric ? "right" : "left"}
                              sortDirection={false}
                              sx={{
                                width: column.width || "fit-content",
                                minWidth: column.width || "fit-content",
                              }}
                            >
                              {column.label}
                            </TableCellStyled>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray
                          name="vehicles"
                          render={(arrayHelpers) => (
                            <>
                              {values?.vehicles &&
                                values?.vehicles?.length > 0 &&
                                values?.vehicles?.map((parking, index) => {
                                  return (
                                    <TableRowStyled
                                      key={1}
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DropdownFM2
                                          options={
                                            wingsData
                                              ? wingsData?.map((item) => ({
                                                  label: item?.number,
                                                  value: item?.wing_id,
                                                }))
                                              : []
                                          }
                                          placeholder="Select "
                                          className="text-[11px] ms-4"
                                          width="115px"
                                          height="37px"
                                          name={`vehicles.${index}.wingId`}
                                          onSelect={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.wingId`,
                                              e.value
                                            );
                                          }}
                                          value={
                                            parking?.wingId != 0 && wingsData
                                              ? {
                                                  label: wingsData.find(
                                                    (item) =>
                                                      item?.wing_id ==
                                                      parking?.wingId
                                                  )?.number,
                                                  value: parking?.wingId,
                                                }
                                              : []
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DropdownFM2
                                          options={
                                            parking?.flatList
                                              ? values?.vehicles[
                                                  index
                                                ]?.flatList.map((item) => ({
                                                  label: item?.flat_no,
                                                  value: item?.flat_id,
                                                }))
                                              : []
                                          }
                                          placeholder="Select "
                                          className="text-[11px] ms-4"
                                          width="115px"
                                          height="37px"
                                          name={`vehicles.${index}.flatId`}
                                          onSelect={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.flatId`,
                                              e.value
                                            );
                                          }}
                                          value={
                                            parking.flatId != "0" &&
                                            parking.flatList.length
                                              ? {
                                                  label: values?.vehicles[
                                                    index
                                                  ]?.flatList.find(
                                                    (item) =>
                                                      item?.flat_id ==
                                                      parking?.flatId
                                                  )?.flat_no,
                                                  value: parking?.flatId,
                                                }
                                              : []
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Sticker No."
                                          className="w-full mb-1"
                                          value={parking.stickerNo}
                                          type="text"
                                          name={`vehicles.${index}.stickerNo`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.stickerNo`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DropdownFM2
                                          options={
                                            parkingTypeList?.map((item) => ({
                                              value: item.parking_type_id,
                                              label: item.title,
                                            })) || []
                                          }
                                          placeholder="Select "
                                          className="text-[11px] ms-4"
                                          width="115px"
                                          height="37px"
                                          name={`vehicles.${index}.parkingType`}
                                          onSelect={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.parkingType`,
                                              e.value
                                            );
                                          }}
                                          value={
                                            values?.vehicles?.[index]
                                              .parkingType
                                              ? {
                                                  label: parking?.parkingType,
                                                  value: parkingTypeList.find(
                                                    (item) =>
                                                      item.title ==
                                                      parking?.parkingType
                                                  )?.parking_type_id,
                                                }
                                              : null
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Parking No."
                                          className="w-full mb-1"
                                          value={parking.parkingNo}
                                          type="text"
                                          name={`vehicles.${index}.parkingNo`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.parkingNo`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Location"
                                          className="w-full mb-1"
                                          value={parking.location}
                                          type="text"
                                          name={`vehicles.${index}.location`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.location`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DropdownFM2
                                          options={vehicleTypeList}
                                          placeholder="Select "
                                          className="text-[11px] ms-4"
                                          width="115px"
                                          height="37px"
                                          name={`vehicles.${index}.vehicleType`}
                                          onSelect={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.vehicleType`,
                                              e.value
                                            );
                                          }}
                                          value={
                                            parking.vehicleType
                                              ? {
                                                  label: vehicleTypeList.find(
                                                    (item) =>
                                                      item.value ==
                                                      parking.vehicleType
                                                  )?.label,
                                                  value: parking.vehicleType,
                                                }
                                              : null
                                          }
                                        />
                                        {console.log(
                                          parking.vehicleType,
                                          "fadsf"
                                        )}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Vehicle No."
                                          className="w-full mb-1"
                                          value={parking.vehicleNo}
                                          type="text"
                                          name={`vehicles.${index}.vehicleNo`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.vehicleNo`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Brand Name"
                                          className="w-full mb-1"
                                          value={parking.vehicleBrand}
                                          type="text"
                                          name={`vehicles.${index}.vehicleBrand`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.vehicleBrand`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Model No."
                                          className="w-full mb-1"
                                          value={parking.modal}
                                          type="text"
                                          name={`vehicles.${index}.modal`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.modal`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Year"
                                          className="w-full mb-1"
                                          value={parking.year}
                                          type="text"
                                          name={`vehicles.${index}.year`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `vehicles.${index}.year`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled>
                                        {values?.vehicles.length > 1 && (
                                          <button
                                            className="p-2 fill-red-650 mt-1"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                            type="button"
                                          >
                                            <DeleteIcon />
                                          </button>
                                        )}
                                      </TableCellStyled>
                                    </TableRowStyled>
                                  );
                                })}
                            </>
                          )}
                        ></FieldArray>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <div className="w-full mt-4">
                  <p className="font-semibold text-sm text-center mt-6 mb-4">
                    {/* Note: Please do not leave any field empty except annual property
          tax, if it is not applied */}
                  </p>
                  <div className="">
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center bg-[#F9FAFC] cursor-pointer"
                    >
                      <label
                        htmlFor="fileInput"
                        className="block w-full cursor-pointer p-9"
                      >
                        <div className="flex justify-center flex-col">
                          <div className="flex justify-center">
                            <UploadEx className="w-6" />
                          </div>
                          <input
                            type="file"
                            id="fileInput"
                            className="hidden"
                            accept={
                              ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            }
                            onChange={handleFileChange}
                            ref={inputFile}
                          />
                          <label
                            htmlFor="fileInput"
                            className="block font-semibold text-sm"
                          >
                            Upload .xlsx file
                          </label>
                          <p className="text-[#AAAAAA] text-[13px]">
                            or drag and drop
                          </p>
                        </div>
                      </label>
                    </div>
                    <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibol text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
                      {file && `File Name: ${file?.name}`}
                    </div>
                    <div className="w-[60%] ml-48 flex justify-between text-red-650 cursor-pointer text-xs font-semibold">
                      <div onClick={handleDownloadExcel}>Download Sample</div>
                      {/* <div onClick={handleDownloadFormatExcel}>
                        Download Format
                      </div> */}
                    </div>
                    {error && !uploadLoader && (
                      <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                        Error:
                        <br />
                        {error.split(". ")?.map((item) => (
                          <div>{item}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default BulkUploadsSocietyParkingDetails;
