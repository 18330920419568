import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import { Route, Routes } from "react-router-dom";
import * as routes from "./routes";
import * as permissions from "./permissions";
import Login from "../pages/AuthModule/Login/Login";
import ForgotPassword from "../pages/AuthModule/ForgetPassword/ForgotPassword";
import Home from "../pages/Layout/HomeLayout";
import ContactsList from "../components/ImpContactsComponents/ContactsList";
import AddContact from "../components/ImpContactsComponents/AddContact";
import ManageCategory from "../components/ImpContactsComponents/ManageCategory";
import ViewContactDetails from "../components/ImpContactsComponents/ViewContactDetails";
import ResidentVendors from "../components/ImpContactsComponents/ResidentVendors";
import AddResidentVendor from "../components/ImpContactsComponents/AddResidentVendor";
import SocietyVendors from "../components/ImpContactsComponents/SocietyVendors";
import VendorAuditLog from "../components/AuditLogsandAccordions/VendorAuditLog";
import ArchivedAMC from "../components/ImpContactsComponents/ArchivedAMC";
import AddVendor from "../components/ImpContactsComponents/AddVendor";
import SocietyProfile from "../pages/SetupRecords/Building/SocietyProfile";
import EditSocietyProfile from "../pages/SetupRecords/Building/EditSocietyProfile";
import SocietyProfileAuditLog from "../pages/SetupRecords/Building/SocietyProfileAuditLogs";
import ChangePassword from "../pages/SetupRecords/Building/ChangePassword";
import SpecificDetails from "../pages/SetupRecords/Building/SpecificDetails";
import WingsandFlats from "../pages/SetupRecords/Building/WingsandFlats";
import ManageFlats from "../pages/SetupRecords/Building/ManageFlats";
import BillTarrifSetup from "../pages/SetupRecords/Building/BillTarrifSetup";
import UtilityReading from "../pages/SetupRecords/Building/UtilityReading";
import BankDetails from "../pages/SetupRecords/Building/BankDetails";
import ResetPassword from "../pages/AuthModule/ResetPassword/ResetPassword";
import TenantApplicationForm from "../pages/SetupRecords/OccupantsAndTenants/ApplyForTenancy/TenantApplicationForm";
import OccupantsInfo from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/OccupantsInfo";
import AddOccupants from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/AddOccupants";
import EditMemberDetails from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/EditMemberDetails";
import MembersDetails from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/MemberDetails";
import MembersPrivacy from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/MembersPrivacy";
import AddFromExistingOccupant from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/AddFromExistingOccupants";
import TenantsInfo from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/TenantsInfo";
import ArchiveTenants from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/ArchiveTenants";
import AddFromExistingTenant from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/AddTenantFromExisting";
import ViewTenantDetails from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/ViewTenantsDetails";
import EditTenantDetails from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/EditTenantDetails";
import AddTenant from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/AddTenant";
import RenewTenancy from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/RenewTenancy";
import OnlineRegFormForRentPayment from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/OnlineRegFormForRentPayment";
import OnlineRentRegistrationList from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/RegistrationRequestList";
import AccessSettings from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/AccessSettings";
import LeaveandLicense from "../pages/SetupRecords/OccupantsAndTenants/LeaveandLicense/LeaveandLicense";
import ViewInvites from "../pages/SetupRecords/OccupantsAndTenants/ViewInvites/ViewInvites";
import SocietyStaffList from "../pages/SetupRecords/StaffManagement/StaffPersonnel/SocietyStaffList";
import AddSocietyStaff from "../pages/SetupRecords/StaffManagement/StaffPersonnel/AddSocietyStaffForm";
import MaintenanceStaff from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/MaintenanceStaff/MaintenanceStaff";
import AddMaintenanceStaff from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/MaintenanceStaff/AddMaintenanceStaff";
import Agency from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/Agency/Agency";
import AddAgency from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/Agency/AddAgency";
import ViewStaffDetails from "../pages/SetupRecords/StaffManagement/StaffPersonnel/ViewStaffDetails";
import ManagingCommittee from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ManagingCommittee";
import ArchiveCommitteeMember from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ArchiveCommitteeMember";
import AddCommittee from "../pages/SetupRecords/StaffManagement/ManagingCommittee/AddManagingCommittee";
import AccountLedgerMapping from "../pages/SetupRecords/BillingInvoice/AccountLedgerMapping";
import AddMoreHeads from "../pages/SetupRecords/BillingInvoice/AddMoreHeads";
import EditMoreHeads from "../pages/SetupRecords/BillingInvoice/EditMoreHeads";
import InvoiceSeriesSetup from "../pages/SetupRecords/BillingInvoice/InvoiceSeriesSetup";
import ComplaintDashboard from "../pages/Desk/ComplaintDashboard/ComplaintDashboard";
import ComplaintAging from "../pages/Desk/ComplaintDashboard/ComplaintAging";
import ViewComplaint from "../pages/Desk/ComplaintDashboard/ViewComplaint";
import AssignComplaint from "../pages/Desk/ComplaintDashboard/AssignComplaint";
import LogAComplaint from "../pages/Desk/LogAComplaint/LogAComplaint";
import UploadDocument from "../pages/Documents/UploadDocument";
import ManageComplaintCategory from "../pages/Desk/ManageComplaintCatergory/ManageComplaintCategory";
import AddComplaintCategory from "../pages/Desk/ManageComplaintCatergory/AddComplaintCategory";
import EditComplaintCategory from "../pages/Desk/ManageComplaintCatergory/EditComplaintCategory";
import EditComplaintEscalationLevelSetting from "../pages/Desk/ComplaintEscalationLevelSetting/EditComplaintEscalationLevelSetting";
import MyInvoice from "../pages/Finance/MyInvoice/MyInvoice";
import CreditDebitNoteCause from "../pages/Finance/FinanceSettings/CreditDebitNoteCause";
import ViewNoteCause from "../pages/Finance/FinanceSettings/ViewNoteCause";
import AddCause from "../pages/Finance/FinanceSettings/AddCauseForm";
import DebitnoteSetting from "../pages/Finance/FinanceSettings/CreditDebitnoteCauseSetting";
import CreditNoteSetting from "../pages/Finance/FinanceSettings/CreditNoteSetting";
import MortgageRegister from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/MortgageRegisterForm";
import EditCause from "../pages/Finance/FinanceSettings/EditCauseForm";
import CreditDebitAuditLog from "../pages/Finance/FinanceSettings/CreditDebitNoteCauseAuditLogs";
import DueReminder from "../pages/Finance/FinanceSettings/DueReminder/DueReminder";
import CommitteeDetailsAudit from "../pages/SetupRecords/StaffManagement/ManagingCommittee/CommitteeDetailsAudit";
import CommitteeDetailsMembersAudit from "../pages/SetupRecords/StaffManagement/ManagingCommittee/CommitteeDetailsMembersAuditLog";
import EditSocietyStaff from "../pages/SetupRecords/StaffManagement/StaffPersonnel/EditSocietyStaffForm";
import InvoiceSettingTaxableFormat from "../pages/SetupRecords/BillingInvoice/InvoiceSettingsTaxableFormat";
import TaxableFormatSubHeadList from "../pages/SetupRecords/BillingInvoice/TaxableFormatSubHead";
import InvoiceSettingFunctionalSettings from "../pages/SetupRecords/BillingInvoice/InvoiceSetting";
import PermissionOnComponent from "./PermissionOnComponent";
import CreateBankAccount from "../pages/SetupRecords/Building/CreateBankAccount";
import WingsAuditLog from "../pages/SetupRecords/Building/WingsAuditLogs";
import BankDetailsAuditLog from "../pages/SetupRecords/Building/BankAuditLogs";
import EmployeeList from "../pages/SetupRecords/StaffManagement/EmployeeManagement/EmployeeList";
import AddEmployeeForm from "../pages/SetupRecords/StaffManagement/EmployeeManagement/AddEmployeeForm";
import ExisitingEmployeeScreen from "../pages/SetupRecords/StaffManagement/EmployeeManagement/ExisitingEmployeeScreen";
import EmployeeLoginReport from "../pages/SetupRecords/StaffManagement/EmployeeManagement/EmployeeLoginReport";
import EmployeeReportRecords from "../pages/SetupRecords/StaffManagement/EmployeeManagement/EmployeeReportRecords";
import ViewEmployee from "../pages/SetupRecords/StaffManagement/EmployeeManagement/ActionsEmployeeTable/ViewEmployee";
import EmployeeAudit from "../pages/SetupRecords/StaffManagement/EmployeeManagement/ActionsEmployeeTable/EmployeeAudit";
import EditEmployee from "../pages/SetupRecords/StaffManagement/EmployeeManagement/ActionsEmployeeTable/EditEmployee";
import EditCommittee from "../pages/SetupRecords/StaffManagement/ManagingCommittee/EditManagingCommittee";
import OccupantsAuditLog from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/OccupantsAuditLogs";
import InvoiceDetails from "../pages/Finance/FinanceSettings/InvoiceDetails/InvoiceDetailsMaintenance";
import MemberDueReminder from "../pages/Finance/FinanceSettings/DueReminder/MemberDueReminder";
import FixedDepositMaturityReminder from "../pages/Finance/FinanceSettings/DueReminder/FixedDepositMaturity";
import NominationReminder from "../pages/Finance/FinanceSettings/DueReminder/NominationReminder";
import AddBankAccount from "../pages/SetupRecords/Building/AddBankAccount";
import ComplaintAllDocuments from "../pages/Documents/DocumentList";
import CreateFolder from "../pages/Documents/CreateFolder";
import EditDocument from "../pages/Documents/EditDocument";
import CreateSubFolder from "../pages/Documents/CreateSubFolder";
import ArchiveOccupantsMembers from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/ArchiveMembers";
import AdvanceNote from "../pages/Finance/BillingManagement/ManageAdvanceNote/ManageAdvanceNote";
import AddAdvanceNote from "../pages/Finance/BillingManagement/ManageAdvanceNote/AddAdvanceNote";
import FamilyMembersList from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyMember/NoOfFamilyMember";
import AddFamilyMember from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyMember/AddFamilyMember";
import TenantsAuditList from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/TenantsAuditList";
import VehiclesDetails from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/VehiclesDetails";
import AddNomineesDetails from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/AddNomineesDetails";
import RevokeList from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/RevokeList";
import DebitNoteHistory from "../pages/Finance/BillingReport/DebitNoteHistory";
import DeleteReceipts from "../pages/Finance/BillingReport/DeleteReceipts";
import ChequeDepositSlip from "../pages/Finance/BillingReport/ChequeDepositSlip";
import UploadFlatDetails from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/UploadFlatDocuments";
import OccupantsStaffList from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/OccupantStaffList";
import EditFamilyMember from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyMember/EditFamilyMember";
import ViewMaintenanceStaffDetails from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/MaintenanceStaff/ViewMaintenanceStaff";
import EditMaintenanceStaff from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/MaintenanceStaff/EditMaintenanceStaff";
import ViewAgency from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/Agency/ViewAgency";
import EditAgency from "../pages/SetupRecords/StaffManagement/MaintenanceAgency/Agency/EditAgency";
import ViewManagingCommittee from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ViewManagingCommittee";
import RetireManagingCommitteeMember from "../pages/SetupRecords/StaffManagement/ManagingCommittee/RetireManaginCommittee";
import CommitteeAccessSettings from "../pages/SetupRecords/StaffManagement/ManagingCommittee/CommitteAccessSettings";
import ViewCommitteeAccessSettings from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ViewCommitteeAccess";
import ViewOnlineRegFormForRentPayment from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/ViewOnlineRegFormForRentPayment";
import ResignManagingCommittee from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ResignManagingCommittee";
import Card from "../components/IdCard/IdCard";
import BulkUploadOccupants from "../pages/BulkUploads/BulkUploadsOccupants";
import UpdateMemberExcel from "../pages/BulkUploads/UpdateMemberExcel";
import BulkUploadTenants from "../pages/BulkUploads/BulkUploadsTenants";
import MaintenanceStaffIdCard from "../components/IdCard/MaintenanceStaffIdCard";
import BulkUploadStaffPersonnel from "../pages/BulkUploads/BulkUploadsStaffPersonnel";
import BulkUploadOpeningBalance from "../pages/BulkUploads/BulkUploadsOpeningAccount";
import Print from "../components/PrintLogic/PrintLogic";
import InvoiceSettingsDisplay from "../pages/SetupRecords/BillingInvoice/InvoiceSettingDisplay";
import GstAndNocSetting from "../pages/SetupRecords/BillingInvoice/GstAndNocSettings";
import PrintTenantsList from "../pages/SetupRecords/OccupantsAndTenants/TenantsInfo/PrintTenantsList";
import ManageInvoiceCurrent from "../pages/Finance/BillingManagement/ManageInvoice/ManageInvoice";
import ManageInvoicePrevious from "../pages/Finance/BillingManagement/ManageInvoice/ManageInvoicePrevious";
import ManageInvoiceDraft from "../pages/Finance/BillingManagement/ManageInvoice/ManageInvoiceDraft";
import GenerateInvoice from "../pages/Finance/BillingManagement/ManageInvoice/GenerateInvoice";
import GenerateCurrentInvoice from "../pages/Finance/BillingManagement/ManageInvoice/GenerateCurrentInvoice";
import ReceiptsTransactions from "../pages/Finance/BillingReport/ReceiptsTransactions";
import IncompleteTransactions from "../pages/Finance/BillingReport/IncompleteTransactions";
import MemberDueSummary from "../pages/Finance/BillingReport/MemberDueSummary";
import MemberBillDueReport from "../pages/Finance/BillingReport/MemberBillDueReport";
import OpeningBalance from "../pages/Finance/BillingReport/OpeningBalance";
import Reviews from "../pages/SetupRecords/SocietyProfileSetup/Review";
import ContentManagementTemplate from "../pages/SetupRecords/SocietyProfileSetup/ContentManagmentTemplate";
import ViewContentManagment from "../pages/SetupRecords/SocietyProfileSetup/ViewContentManagment";
import EditContentManagment from "../pages/SetupRecords/SocietyProfileSetup/EditContentManagment";
import ContentManagmentTemplateAudit from "../pages/SetupRecords/SocietyProfileSetup/AuditContentManagment";
import SmsCreditPackages from "../pages/SetupRecords/SocietyProfileSetup/SmsCreditPackage";
import PrintEmployeeLoginReport from "../pages/SetupRecords/StaffManagement/EmployeeManagement/PrintEmployeeLoginReport";
import PrintEmployeeReportRecords from "../pages/SetupRecords/StaffManagement/EmployeeManagement/PrintEmployeeReportRecords";
import SmsCreditOrderHistory from "../pages/SetupRecords/SocietyProfileSetup/SmsCreditOrderHistory";
import ViewSmsOrder from "../pages/SetupRecords/SocietyProfileSetup/ViewSmsOrder";
import SmsHistoryList from "../pages/SetupRecords/SocietyProfileSetup/SmsHistoryList";
import ViewArchiveCommitteeMember from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ViewArchiveCommitteeMember";
import ManageEmployeeRoles from "../pages/SetupRecords/ManageRolesRights/ManageEmployeeRoles/ManageEmployeeRoles";
import AddEmployeeRoles from "../pages/SetupRecords/ManageRolesRights/ManageEmployeeRoles/AddEmployeeRoles";
import ViewEmployeeRoles from "../pages/SetupRecords/ManageRolesRights/ManageEmployeeRoles/ViewEmployeeRole";
import EditEmployeeRoles from "../pages/SetupRecords/ManageRolesRights/ManageEmployeeRoles/EditEmployeeRoles";
import ManageoccupantsRolesTable from "../pages/SetupRecords/ManageRolesRights/ManageOccupantsRoles/ManageOccupantsRoles";
import AddOccupantRoles from "../pages/SetupRecords/ManageRolesRights/ManageOccupantsRoles/AddOccupantRoles";
import ViewOccupantRoles from "../pages/SetupRecords/ManageRolesRights/ManageOccupantsRoles/ViewOccupantRole";
import EditOccupantRoles from "../pages/SetupRecords/ManageRolesRights/ManageOccupantsRoles/EditOccupantRoles";
import ManageSocietyRoles from "../pages/SetupRecords/ManageRolesRights/ManageSocietyRoles/ManageSocietyRoles";
import AddFamilyRoles from "../pages/SetupRecords/ManageRolesRights/ManageFamilyRoles/AddFamilyRoles";
import ViewFamilyRoles from "../pages/SetupRecords/ManageRolesRights/ManageFamilyRoles/ViewFamilyRole";
import EditFamilyRoles from "../pages/SetupRecords/ManageRolesRights/ManageFamilyRoles/EditFamilyRoles";
import ManageFamilyRoles from "../pages/SetupRecords/ManageRolesRights/ManageFamilyRoles/ManageFamilyRoles";
import SocietyFormsandBylaws from "../pages/SetupRecords/ByLaws/SocietyFormsandBylaws";
import ApproveResign from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ApproveResign";
import ReappointMember from "../pages/SetupRecords/StaffManagement/ManagingCommittee/ReappointMember";
import PaymentAndAdjust from "../pages/Finance/BillingManagement/ManageInvoice/PaymentandAdjustment";
import MakePaymentS from "../pages/Finance/MakePayment/MakePayment";
import ViewInvoices from "../pages/Finance/MyInvoice/ViewInvoiceDetails";
import ManageDebitNoteCurrent from "../pages/Finance/BillingManagement/ManageDebitNote/ManageDebitNote";
import ManageDebitPrevious from "../pages/Finance/BillingManagement/ManageDebitNote/ManageDebitNotePrevious";
import ManageDebitDraft from "../pages/Finance/BillingManagement/ManageDebitNote/ManageDebitNoteDraft";
import ComplaintGeneralSettings from "../pages/Desk/GeneralSettings/ComplaintGeneralSettings";
import AddDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/AddDebitNote";
import MultipleDebitNoteDetail from "../pages/Finance/BillingManagement/ManageDebitNote/MultipleDebitNoteDetail";
import EditDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/EditDebitNote";
import EditDebitNoteDraft from "../pages/Finance/BillingManagement/ManageDebitNote/EditDebitNoteDraft";
import PaymentAndAdjustment from "../pages/Finance/BillingManagement/ManageDebitNote/PaymentandAdjustment";
import MakePaymentDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/MakePayment/MakePayment";
import EditPaymentDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/MakePayment/EditPayment";
import MakeMultiPaymentDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/MakeMultipayment";
import MakePaymentSuccessDebitNote from "../pages/Finance/BillingManagement/ManageDebitNote/MakePayment/PaymentSuccess";
import DebitNoteInvoiceReport from "../pages/Reports/FinanceReport/DebitNoteInvoiceReport/DebitNoteInvoiceReport";
import PrintDebitNoteInoviceReport from "../pages/Reports/FinanceReport/DebitNoteInvoiceReport/PrintDebitNoteInoviceReport";
import InvoiceReport from "../pages/Reports/FinanceReport/InvoiceReport/InvoiceReport";
import PrintInoviceReport from "../pages/Reports/FinanceReport/InvoiceReport/PrintInvoiceReport";
import ReceiptReport from "../pages/Reports/FinanceReport/ReceiptReport/ReceiptReport";
import PrintReceiptReport from "../pages/Reports/FinanceReport/ReceiptReport/PrintReceiptReport";
import SupplementaryBillReport from "../pages/Reports/FinanceReport/SupplementaryBillReport/SupplementaryBillReport";
import PrintSupplementaryBillReport from "../pages/Reports/FinanceReport/SupplementaryBillReport/PrintSupplementaryBillReport";
import DebitNoteReceiptReport from "../pages/Reports/FinanceReport/DebitNoteReceiptReport/DebitNoteReceiptReport";
import PrintDebitNoteReceiptReport from "../pages/Reports/FinanceReport/DebitNoteReceiptReport/PrintDebitNoteReceiptReport";
import DeletedInvoiceReport from "../pages/Reports/FinanceReport/DeletedInvoiceReport/DeletedInvoiceReport";
import PrintDeletedInvoiceReport from "../pages/Reports/FinanceReport/DeletedInvoiceReport/PrintDeletedInvoiceReport";
import DeletedDebitNoteReport from "../pages/Reports/FinanceReport/DeletedDebitNoteReport/DeletedDebitNoteReport";
import PrintDeletedDebitNoteReport from "../pages/Reports/FinanceReport/DeletedDebitNoteReport/PrintDeletedDebitNoteReport";
import InvoiceInterestReport from "../pages/Reports/FinanceReport/InvoiceInterestReport/InvoiceInterestReport";
import PrevInvoiceInterestReport from "../pages/Reports/FinanceReport/InvoiceInterestReport/PrevInvoiceInterestReport";
import InterestCalculationReport from "../pages/Reports/FinanceReport/InterestCalculationReport/InterestCalculationReport";
import PrintInterestCalculationReport from "../pages/Reports/FinanceReport/InterestCalculationReport/PrintInterestCalculationReport";
import ReconciliationReport from "../pages/Reports/FinanceReport/ReconciliationReport/ReconciliationReport";
import PrintReconciliationReport from "../pages/Reports/FinanceReport/ReconciliationReport/PrintReconciliationReport";
import DebitNoteInterestCalculationReport from "../pages/Reports/FinanceReport/DebitNoteInterestCalculationReport/DebitNoteInterestCalculationReport";
import PrintDebitNoteInterestCalculationReport from "../pages/Reports/FinanceReport/DebitNoteInterestCalculationReport/PrintDebitNoteInterestCalculationReport";
import InvoiceAgingReport from "../pages/Reports/FinanceReport/InvoiceAgingReport/InvoiceAgingReport";
import VisitorDashboard from "../pages/GateManagement/VisitorDashboard/VisitorDashboard";
import PrintChequeDepositSlip from "../pages/Finance/BillingReport/PrintChequeDepositSlip";
import PrintMemberDueSummary from "../pages/Finance/BillingReport/PrintMemberDueSummary";
import PrintMemberBillDueReport from "../pages/Finance/BillingReport/PrintMemberBillDueReport";
import ManageParkingType from "../pages/GateManagement/ParkingManagement/ManageParkingType";
import SocietyParkingCharges from "../pages/GateManagement/ParkingManagement/ParkingCharges/SocietyParkingCharges";
import EditSocietyParkingCharges from "../pages/GateManagement/ParkingManagement/ParkingCharges/EditParkingTypeCharges";
import ParkingChargesAuditLogs from "../pages/GateManagement/ParkingManagement/ParkingCharges/ParkingChargesAuditLog";
import CreditDebitAllAuditLog from "../pages/Finance/FinanceSettings/CreditDebitNoteCauseAllAuditLogs";
import ViewBookings from "../pages/Club/ViewBookings/ViewBookings";
import ViewBookingDetails from "../pages/Club/ViewBookings/ViewBookingDetails";
import PrintDebitNoteHistory from "../pages/Finance/BillingReport/PrintDebitNoteHistory";
import ViewDeletedReceipt from "../pages/Finance/BillingReport/ViewDeletedReceipt";
import VehicleDashboard from "../pages/GateManagement/VehicleDashboard/VehicleDashboard";
import MakePaymentBookings from "../pages/Club/AllFacility/MakePaymentBookings";
import PrintVehicleLogs from "../pages/GateManagement/VehicleDashboard/PrintVehicleLogs";
import FacilityReport from "../pages/Club/ViewBookings/FacilityReport";
import AllFacility from "../pages/Club/AllFacility/AllFacility";
import AddFacility from "../pages/Club/AddFacility/AddFacility";
import AddTimeSlot from "../pages/Club/AddTimeSlot/AddTimeSlot";
import AddRateChart from "../pages/Club/AddRateChart/AddRateChart";
import EditComplaint from "../pages/Desk/ComplaintDashboard/EditComplaint";
import CountedInvoiceReport from "../pages/Finance/BillingReport/CountedInvoiceReport";
import ViewFacilityDetails from "../pages/Club/AllFacility/ViewFacility";
import EditFacility from "../pages/Club/AllFacility/EditFacility";
import MakeBooking from "../pages/Club/AllFacility/MakeBooking";
import PrintVisitorLog from "../pages/GateManagement/VisitorDashboard/PrintVisitorLog";
import PrintVisitorPass from "../pages/GateManagement/VisitorDashboard/PrintVisitorPass";
import EditRateChart from "../pages/Club/AllFacility/EditRateChart";
import EditTimeSlot from "../pages/Club/AllFacility/EditTimeSlot";
import PrintFacilityReport from "../pages/Club/ViewBookings/PrintFacilityReport";
import IForm from "../pages/Compliance/Registers/IForm";
import LienMortgageRegister from "../pages/Compliance/Registers/LienMortgageRegister";
import DocumentsIssued from "../pages/Compliance/ComplianceAdministration/DocumentsIssued";
import IssueDocument from "../pages/Compliance/ComplianceAdministration/IssueDocument";
import InvoiceList from "../pages/SetupRecords/BillingInvoice/InvoiceSettings/InvoiceList";
import EditInvoiceSetting from "../pages/SetupRecords/BillingInvoice/InvoiceSettings/EditInvoiceSetting";
import FacilityAuditLogs from "../pages/Club/ViewBookings/FacilityAuditLogs";
import PrintBookingReciept from "../pages/Club/ViewBookings/PrintBookingReciept";
import BookingPaymentAndAdjust from "../pages/Club/ViewBookings/Payment&AdjustmentBookings";
import ManageCreditNote from "../pages/Finance/BillingManagement/ManageCreditNote/CreditNote";
import ViewDocIssued from "../pages/Compliance/ComplianceAdministration/ViewDocIssued";
import EditDocIssued from "../pages/Compliance/ComplianceAdministration/EditDocIssued";
import ViewDebitNoteDetails from "../pages/Finance/BillingManagement/ManageDebitNote/ViewDebitNote";
import ViewDebitNotePrevious from "../pages/Finance/BillingManagement/ManageDebitNote/ViewDebitNotePrevious";
import TimeSlotTypeList from "../pages/Club/AddTimeSlot/TimeSlotType/TimeSlotTypeList";
import AddCreditNote from "../pages/Finance/BillingManagement/ManageCreditNote/AddCreditNote";
import PrintCreditNoteReciept from "../pages/Finance/BillingManagement/ManageCreditNote/PrintCreditNoteReciept";
import ViewFacilityAuditLogs from "../pages/Club/AllFacility/ViewFacilityAuditLogs";
import EditCreditNote from "../pages/Finance/BillingManagement/ManageCreditNote/EditCreditNote";
import CreditPayment from "../pages/Finance/BillingManagement/ManageCreditNote/CreditPayment";
import PrintInvoiceReceipt, { PrintInvoiceReceiptMulti } from "../pages/Finance/BillingManagement/ManageInvoice/PrintInvoiceReceipt";
import PrintInvoiceBill, { PrintInvoiceBillMulti } from "../pages/Finance/BillingManagement/ManageInvoice/PrintInvoiceBill";
import SavedNotice from "../pages/Compliance/Meetings/SavedNotice/SavedNotice";
import SentMinutes from "../pages/Compliance/Meetings/SavedNotice/SentMinutes";
import SelectMemberForMinutes from "../pages/Compliance/Meetings/ScheduleAMeeting/SelectMemberForMinutes";
import ViewSavedNotice from "../pages/Compliance/Meetings/SavedNotice/ViewSavedNotice";
import ViewManageInvoices from "../pages/Finance/BillingManagement/ManageInvoice/ViewManageInvoiceDetails";
import EditSavedNotice from "../pages/Compliance/Meetings/SavedNotice/EditSavedNotice";
import AuditLogSavedNotice from "../pages/Compliance/Meetings/SavedNotice/AuditLogSavedNoitce";
import SentNotice from "../pages/Compliance/Meetings/SentNotice/SentNotice";
import SentNoticeSentMinutes from "../pages/Compliance/Meetings/SentNotice/SentNoticeSentMinutes";
import ViewSentNotice from "../pages/Compliance/Meetings/SentNotice/ViewSentNotice";
import RescheduleSentNotice from "../pages/Compliance/Meetings/SentNotice/RescheduleSentNotice";
import AuditLogSentNotice from "../pages/Compliance/Meetings/SentNotice/AuditLogSentNotice";
import UploadCreditNote from "../pages/Finance/BillingManagement/ManageCreditNote/UploadCreditNote";
import PrintAdvanceNoteReciept from "../pages/Finance/BillingManagement/ManageAdvanceNote/PrintAdvanceNoteReciept";
import EditAdvanceNote from "../pages/Finance/BillingManagement/ManageAdvanceNote/EditAdvanceNote";
import ScheduleAMeeting from "../pages/Compliance/Meetings/ScheduleAMeeting/ScheduleAMeeting";
import CreateNotice from "../pages/Compliance/Meetings/ScheduleAMeeting/CreateNotice";
import CreateMinutesForMeetings from "../pages/Compliance/Meetings/CreateMinutes/CreateMinutesForMeeting";
import SavedMinutes from "../pages/Compliance/Meetings/SavedMinutes/SavedMinutes";
import CreateMinutes from "../pages/Compliance/Meetings/CreateMinutes/CreateMinutes";
import DepositDueRemider from "../pages/Finance/FinanceSettings/DueReminder/DepositDueRemider";
import PrintAdvanceNoteList from "../pages/Finance/BillingManagement/ManageAdvanceNote/PrintAdvanceNoteList";
import PrintDebitBill from "../pages/Finance/BillingManagement/ManageDebitNote/PrintDebitBill";
import PrintDebitReceipt from "../pages/Finance/BillingManagement/ManageDebitNote/PrintDebitReceipt";
import JForm from "../pages/Compliance/Registers/JForm";
import ViewSavedMinutes from "../pages/Compliance/Meetings/SavedMinutes/ViewSavedMinutes";
import MakePaymentForBooking from "../pages/Club/ViewBookings/MakePaymentForBooking";
import AutoSignIn from "../pages/AuthModule/AutoSignIn/AutoSignIn";
import PrintIForm from "../pages/Compliance/Registers/PrintIForm";
import PrintShareRegister from "../pages/Compliance/Registers/PrintShareRegister";
import PrintNominationRegister from "../pages/Compliance/Registers/PrintNominationRegister";
import PrintPropertyRegister from "../pages/Compliance/Registers/PrintPropertyRegister";
import PrintJForm from "../pages/Compliance/Registers/PrintJForm";
import UploadAdvanceNote from "../pages/Finance/BillingManagement/ManageAdvanceNote/UploadAdvanceNote";
import TransferFlat from "../pages/Compliance/ComplianceAdministration/TransferFlat/TransferFlat";
import FlatDocument from "../pages/Compliance/ComplianceAdministration/TransferFlat/FlatDocument";
import TransferFlatForm from "../pages/Compliance/ComplianceAdministration/TransferFlat/TransferFlatForm";
import ViewBillGeneration from "../pages/Reports/OldFinancialReports/BillGenerations/ViewBillGenerations";
import BillReports from "../pages/Reports/OldFinancialReports/BillReports/BillReports";
import AccountReports from "../pages/Reports/OldFinancialReports/AccountReports/AccountReports";
import { DOMESTIC_STAFF_TYPE } from "../consts/constant";
import OutstandingReport from "../pages/Reports/OldFinancialReports/FinanceReports/OutstandingReport";
import PhotoGallery from "../pages/SocialAndCommunications/PhotoGallery/PhotoGallery";
import AlbumPhotos from "../pages/SocialAndCommunications/PhotoGallery/AlbumPhotos";
import NoticeAndCircular from "../pages/SocialAndCommunications/NoticeAndCirculars/NoticeAndCircular";
import ViewNotice from "../pages/SocialAndCommunications/NoticeAndCirculars/ViewNotice";
import PrintNoticeAndCircular from "../pages/SocialAndCommunications/NoticeAndCirculars/PrintNoticeAndCircular";
import NoticeAndCircularArchive from "../pages/SocialAndCommunications/NoticeAndCirculars/NoticeAndCircularArchives";
import ViewNoticeCircularArchive from "../pages/SocialAndCommunications/NoticeAndCirculars/ViewNoticeCircularArchive";
import DraftNoticeAndCircular from "../pages/SocialAndCommunications/NoticeAndCirculars/DraftNoticeAndCircular";
import AcknowledgementListNotice from "../pages/SocialAndCommunications/NoticeAndCirculars/AcknowledgmentListNotice";
import CreateNoticeandCircular from "../pages/SocialAndCommunications/NoticeAndCirculars/CreateNotice";
import ViewFamilyMember from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyMember/ViewFamilyMember";
import EditInvoice from "../pages/Finance/BillingManagement/ManageInvoice/EditInvoice";
import EditPaymentS from "../pages/Finance/MakePayment/EditPayment";
import UploadPaymentExcel from "../pages/Finance/BillingManagement/ManageInvoice/UploadPaymentExcel";
import PrintBookingInvoice from "../pages/Club/ViewBookings/PrintBookingInvoice";
import EditInvoiceDraft, { EditInvoiceDraftMulti } from "../pages/Finance/BillingManagement/ManageInvoice/EditInvoiceDraft";
import CreditNoteandAdvance from "../pages/Finance/BillingManagement/ManageInvoice/CreditNoteAndAdvance";
import EditReceipt from "../pages/Finance/MakePayment/EditReceipt";
import ViewManagePreviousInvoices from "../pages/Finance/BillingManagement/ManageInvoice/ViewManageInvoicePrevious";
import MyInvoicePayment from "../pages/Finance/MyInvoice/MakePayment/MyInvoiceMakePayment";
import PaymentSuccess from "../pages/Finance/MakePayment/PaymentSuccess";
import GenerateUploadExcelFormat from "../pages/Finance/BillingManagement/ManageInvoice/GenerateUploadExcelFormat";
import AddAcknowledgementNotice from "../pages/SocialAndCommunications/NoticeAndCirculars/AddAcknowledgementNotice";
import MakeMultiPayment from "../pages/Finance/BillingManagement/ManageInvoice/MakeMultipayment"
import SocietyEmailReports from "../pages/Reports/SocietyReport/SocietyEmailReports";
import MemberLedgerReports from "../pages/Reports/SocietyReport/MemberLedgerReports";
import ViewIncompleteTransactions from "../pages/Finance/BillingReport/ViewIncompleteTransactions";
import PrintReceiptTransactions from "../pages/Finance/BillingReport/PrintReceiptTransactions";
import ViewReceiptTransaction from "../pages/Finance/BillingReport/ViewReceiptTransaction";
import InvoiceAuditReport from "../pages/Reports/FinanceReport/InvoiceAuditReport/InvoiceAuditReport";
import EInvoiceReport from "../pages/Reports/FinanceReport/EinvoiceReport/EinvoiceReport";
import PostComment from "../pages/Desk/ComplaintDashboard/PostComment";
import LogAComplaintAuditLogs from "../pages/Desk/LogAComplaint/LogAComplaintAuditLog";
import ComplaintCategoryAuditLogs from "../pages/Desk/ManageComplaintCatergory/ComplaintCategoryAuditLog";
import GstReport from "../pages/Reports/FinanceReport/GstReport/GstReport";
import MainVisitorFormPublic from "../pages/GateManagement/VisitorDashboard/MainVisitorFormPublic";
import NoticeAndCircularAuditLogs from "../pages/SocialAndCommunications/NoticeAndCirculars/NoticeAndCircularAuditLog";
import SpecificDetailsAuditLog from "../pages/SetupRecords/Building/SpecificDetailsAuditLogs";
import SocietyStaffAuditLogs from "../pages/SetupRecords/StaffManagement/StaffPersonnel/SocietyStaffAuditLogs";
import FamilyInfo from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyInfo";
import BulkUploadFamily from "../pages/BulkUploads/BulkUploadFamily";
import BulkUploadsSocietyParkingDetails from "../pages/BulkUploads/BulkUploadsSocietyParkingDetails";
import ManageBulk from "../pages/Finance/BillingManagement/ManageBulk/ManageBulk";
import ManageBulkRequest from "../pages/Finance/BillingManagement/ManageBulk/ManageBulkRequest";
import PrintNDC from "../pages/Finance/BillingReport/NoDueCertificate";


import SocietyParkingReports from "../pages/Reports/SocietyReport/SocietyParkingReports";
import PrintSocietyParkingReport from "../pages/Reports/SocietyReport/PrintSocietyParkingReport";
import SocietyDownloadHistory from "../pages/Reports/SocietyReport/SocietyDownloadHistory";
import EditFamilyInfoMember from "../pages/SetupRecords/OccupantsAndTenants/OccupantsInfo/FamilyMember/EditFamilyInfoMember";

const Routers = () => {
  return (
    <div>
      <Routes>
        <Route path={routes.LOGIN_ENDPOINT} element={<Login />} />
        <Route path={`${routes.AUTO_LOGIN_ENDPOINT}/:encData`} element={<AutoSignIn />} />
        <Route path={routes.FORGOTPASSWORD_ENDPOINT} element={<ForgotPassword />} />
        <Route path={routes.RESET_PASSWORD_ENDPOINT} element={<ResetPassword />} />

        <Route path="/" element={<Login />} />
        <Route path={`${routes.SOCIETY_STAFF_ID_CARD_ENDPOINT}/:id`} element={<Card />} />
        <Route
          path={`${routes.PRINT_VISITOR_PASS_ENDPOINT}`}
          element={<PrintVisitorPass />} />

        <Route path={`${routes.ACKNOWLEDGEMENT_FOR_NOTICE_AND_CIRCULARS_ENDPOINT}/:notice_id/:user_id/:user_type`} element={<AddAcknowledgementNotice />} />

        <Route path={`${routes.PRINT_BOOKING_RECIEPT_ENDPOINT}/:id`} element={<PrintBookingReciept />} />

        {/* add visitor public */}
        <Route
          path={routes.ADD_VISITOR_PUBLIC_ENDPOINT}
          element={<MainVisitorFormPublic />}
        />

        <Route element={<ProtectedRoute />}>
          <Route path={routes.HOME_ENDPOINT} element={<Home />} />
          <Route
            path={routes.EMPLOYEE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_PERMISSION]} component={<EmployeeList />} />}
          />
          <Route
            path={routes.ADD_EMPLOYEE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_EMPLOYEE_PERMISSION]} component={<AddEmployeeForm />} />}
          />
          <Route
            path={routes.EXISTING_EMPLOYEE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_EMPLOYEE_PERMISSION]} component={<ExisitingEmployeeScreen />} />}
          />
          <Route
            path={routes.EMPLOYEE_LOGIN_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION]} component={<EmployeeLoginReport />} />}
          />
          <Route
            path={routes.PRINT_EMPLOYEE_LOGIN_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION]} component={<PrintEmployeeLoginReport />} />}
          />
          <Route
            path={routes.EMPLOYEE_RECORDS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION]} component={<EmployeeReportRecords />} />}
          />
          <Route
            path={routes.PRINT_EMPLOYEE_RECORDS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_LOGIN_REPORT_PERMISSION]} component={<PrintEmployeeReportRecords />} />}
          />
          <Route
            path={`${routes.VIEW_EMPLOYEE_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_PERMISSION]} component={<ViewEmployee />} />}
          />

          <Route
            path={`${routes.EDIT_EMPLOYEE_DETAILS_ENDPOINT}/:id/:employeeDetailsId`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_EMPLOYEE_PERMISSION]} component={<EditEmployee />} />}
          />
          <Route
            path={`${routes.AUDIT_EMPLOYEE_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_AUDIT_LOGS_PERMISSION]} component={<EmployeeAudit />} />}
          />
          <Route path={routes.CONTACT_ENDPOINT} element={<ContactsList />} />
          <Route path={routes.ADD_CONTACT_ENDPOINT} element={<AddContact />} />
          <Route
            path={routes.MANAGE_CATEGORY_ENDPOINT}
            element={<ManageCategory />}
          />
          <Route
            path={routes.VIEW_CONTACT_DETAILS_ENDPOINT}
            element={<ViewContactDetails />}
          />
          <Route
            path={routes.RESIDENT_VENDORS_ENDPOINT}
            element={<ResidentVendors />}
          />
          <Route
            path={routes.ADD_RESIDENT_VENDOR_ENDPOINT}
            element={<AddResidentVendor />}
          />
          <Route
            path={routes.SOCIETY_VENDORS_ENDPOINT}
            element={<SocietyVendors />}
          />
          <Route
            path={routes.VENDOR_AUDIT_LOG_ENDPOINT}
            element={<VendorAuditLog />}
          />
          <Route path={routes.ARCHIVEDAMC_ENDPOINT} element={<ArchivedAMC />} />
          <Route
            path={routes.ADD_SOCIETY_VENDOR_ENDPOINT}
            element={<AddVendor />}
          />
          <Route
            path={routes.SOCIETY_PROFILE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_SOCIETY_PROFILE_PERMISSION]} component={<SocietyProfile />} />}
          />
          <Route
            path={routes.EDITSOCIETY_PROFILE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_SOCIETY_PROFILE_PERMISSION]} component={<EditSocietyProfile />} />}
          />
          <Route
            path={routes.SOCIETY_PROFILE_AUDIT_LOGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_SOCIETY_PROFILE_AUDIT_PERMISSION]} component={<SocietyProfileAuditLog />} />}
          />
          <Route
            path={routes.CHANGE_PASSWORD_ENDPOINT}
            // element={<PermissionOnComponent permissionPaths={[permissions.CHANGE_PASSWORD_PERMISSION]} component={<ChangePassword />} />}
            element={<ChangePassword />}
          />
          <Route
            path={routes.SPECIFIC_DETAILS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_SPECIFIC_DETAILS_PERMISSION]} component={<SpecificDetails />} />}
          />
          <Route
            path={routes.SPECIFIC_DETAILS_AUDIT_LOG_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_SPECIFIC_DETAILS_PERMISSION]} component={<SpecificDetailsAuditLog />} />}
          />
          <Route
            path={routes.WINGS_AND_FLATS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_WINGS_PERMISSION]} component={<WingsandFlats />} />}
          />
          <Route path={routes.WINGS_AND_FLATS_AUDIT_LOG_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.VIEW_WINGS_AUDIT_PERMISSION]} component={<WingsAuditLog />} />} />
          <Route
            path={`${routes.MANAGE_FLATS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_WINGS_PERMISSION]} component={<ManageFlats />} />}
          />
          <Route
            path={`${routes.BILL_TARRIF_SETUP_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_BILL_TARIFF_PERMISSION]} component={<BillTarrifSetup />} />}
          />
          <Route
            path={`${routes.UTILITY_READING_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_UTILITY_READING_PERMISSION]} component={<UtilityReading />} />}
          />

          <Route
            path={routes.BANK_DETAILS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_BANK_DETAILS_PERMISSION]} component={<BankDetails />} />}
          />

          <Route
            path={routes.BANK_DETAILS_AUDIT_LOG_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_BANK_DETAILS_AUDIT_LOG_PERMISSION]} component={<BankDetailsAuditLog />} />}
          />

          <Route
            path={routes.CREATE_BANK_ACCOUNT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_BANK_ACCOUNTS_PERMISSION]} component={<CreateBankAccount />} />}
          />

          <Route
            path={routes.ADD_BANK_ACCOUNT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.CREATE_BANK_ACCOUNT_PERMISSION]} component={<AddBankAccount />} />}
          />

          <Route
            path={routes.OCCUPANTS_INFO_ENDPOINT}
            element={<OccupantsInfo />}
          />
          <Route
            path={routes.ADD_OCCUPANTS_ENDPOINT}
            element={<AddOccupants />}
          />
          <Route
            path={routes.ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT}
            element={<ArchiveOccupantsMembers />}
          />
          <Route
            path={`${routes.EDIT_MEMBER_DETAILS_ENDPOINT}/:id/:userId`}
            element={<EditMemberDetails />}
          />
          <Route path={routes.OCCUPANTS_AUDIT_LOG_ENDPOINT} element={<OccupantsAuditLog />} />
          <Route
            path={`${routes.MEMBER_DETAILS_ENDPOINT}/:id/:userId`}
            element={<MembersDetails />}
          />
          <Route
            path={routes.MEMBER_PRIVACY_ENDPOINT}
            element={<MembersPrivacy />}
          />
          <Route
            path={routes.ADD_FROM_EXISITING_OCCUPANT_ENDPOINT}
            element={<AddFromExistingOccupant />}
          />

          <Route
            path={routes.TENANTS_INFO_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TENANTS_PERMISSION]} component={<TenantsInfo />} />}
          />
          <Route
            path={routes.PRINT_TENANTS_INFO_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.PRINT_TENANTS_REPORT_PERMISSION]} component={<PrintTenantsList />} />}
          />
          <Route
            path={routes.TENANTS_AUDIT_LOG_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TENANTS_AUDIT_LOG_PERMISSION]} component={<TenantsAuditList />} />}
          />
          <Route
            path={routes.ARCHIVE_TENANTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_ARCHIVE_TENANTS_PERMISSION]} component={<ArchiveTenants />} />}
          />
          <Route
            path={routes.ADD_FROM_EXISITING_TENANT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_FROM_EXISTING_TENANTS_PERMISSION]} component={<AddFromExistingTenant />} />}
          />
          <Route
            path={`${routes.VIEW_TENANTS_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TENANTS_PERMISSION]} component={<ViewTenantDetails />} />}
          />
          <Route
            path={`${routes.EDIT_TENANTS_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_TENANTS_PERMISSION]} component={<EditTenantDetails />} />}
          />
          <Route
            path={routes.ADD_TENANTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_TENANTS_PERMISSION]} component={<AddTenant />} />}
          />
          <Route
            path={`${routes.RENEW_TENANCY_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.RENEW_TENANT_PERMISSION]} component={<RenewTenancy />} />}
          />

          <Route
            path={routes.FAMILY_INFO_ENDPOINT}
            element={<FamilyInfo />}
          />

          <Route
            path={`${routes.ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT}/:tenantId`}
            element={<PermissionOnComponent permissionPaths={[permissions.PAY_ONLINE_RENT_PERMISSION]} component={<OnlineRegFormForRentPayment />} />}
          />
          <Route
            path={`${routes.VIEW_ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT}/:tenantId`}
            element={<PermissionOnComponent permissionPaths={[permissions.PAY_ONLINE_RENT_PERMISSION]} component={<ViewOnlineRegFormForRentPayment />} />}
          />
          <Route
            path={routes.RENT_REGISTRATION_LIST_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.PAY_ONLINE_RENT_PERMISSION]} component={<OnlineRentRegistrationList />} />}
          />

          <Route
            path={routes.ACCESS_SETTINGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ACCESS_SETTING_PERMISSION]} component={<AccessSettings />} />}
          />


          <Route
            path={routes.LEAVE_AND_LICENSE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_LEAVE_AND_LICENSE_PERMISSION]} component={<LeaveandLicense />} />}
          />

          <Route
            path={routes.APPLY_FOR_TENANCY_ENDPOINT}
            element={<TenantApplicationForm />}
          />

          <Route
            path={routes.VIEW_INVITES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVITIES_PERMISSION]} component={<ViewInvites />} />}
          />

          <Route
            path={`${routes.MORTGAGE_FORM_ENDPOINT}/:society_id/:flat_id`}
            element={<MortgageRegister />}
          />
          <Route path={`${routes.FAMILY_MEMBERS_LIST_ENDPOINT}/:subscribeId/:userId/:flatId`} element={<FamilyMembersList />} />
          <Route path={`${routes.ADD_FAMILY_MEMBERS_ENDPOINT}/:id/:userId/:flatId`} element={<AddFamilyMember />} />
          <Route path={`${routes.EDIT_FAMILY_MEMBERS_ENDPOINT}/:familyMemberId`} element={<EditFamilyInfoMember />} />
          <Route path={`${routes.EDIT_FAMILY_MEMBERS_ENDPOINT}/:id/:userId/:flatId/:familyMemberId`} element={<EditFamilyMember />} />
          <Route path={`${routes.VIEW_FAMILY_MEMBERS_ENDPOINT}/:familyMemberId`} element={<ViewFamilyMember />} />
          <Route path={`${routes.VIEW_FAMILY_MEMBERS_ENDPOINT}/:id/:userId/:flatId/:familyMemberId`} element={<ViewFamilyMember />} />
          <Route path={`${routes.NOMINEES_DETAILS_ENDPOINT}/:subscribeId/:userId/:flatId`} element={<AddNomineesDetails />} />
          <Route path={`${routes.MEMBER_STAFF_DETAILS_ENDPOINT}/:subscribeId/:userId/:flatId`} element={<SocietyStaffList type={DOMESTIC_STAFF_TYPE} />} />
          <Route path={`${routes.ADD_MEMBER_STAFF_ENDPOINT}/:subscribeId/:userId/:flatId`} element={<AddSocietyStaff type={DOMESTIC_STAFF_TYPE} />} />
          <Route path={`${routes.EDIT_MEMBER_STAFF_ENDPOINT}/:subscribeId/:userId/:flatId/:id`} element={<EditSocietyStaff type={DOMESTIC_STAFF_TYPE} />} />
          <Route path={`${routes.VEHICLE_DETAILS_ENDPOINT}/:id/:userId/:flatId`} element={<VehiclesDetails />} />
          <Route path={`${routes.REVOKE_DETAILS_LIST_ENDPOINT}`} element={<RevokeList />} />
          <Route path={`${routes.UPLOAD_FLAT_DOCUMENTS_ENDPOINT}/:flatId`} element={<UploadFlatDetails />} />
          <Route path={`${routes.OCCUPANTS_STAFF_TABLE_ENDPOINT}`} element={<OccupantsStaffList />} />
          <Route path={`${routes.EDIT_SOCIETY_STAFF_AUDIT_LOG_ENDPOINT}`} element={<SocietyStaffAuditLogs />} />
          <Route
            path={routes.SOCIETY_STAFF_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_STAFF_PERMISSION]} component={<SocietyStaffList />} />}
          />
          <Route
            path={routes.ADD_SOCIETY_STAFF_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_STAFF_PERMISSION]} component={<AddSocietyStaff />} />}
          />
          <Route
            path={`${routes.VIEW_SOCIETY_STAFF_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_STAFF_PERMISSION]} component={<ViewStaffDetails />} />}
          />
          <Route
            path={`${routes.EDIT_SOCIETY_STAFF_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_STAFF_PERMISSION]} component={<EditSocietyStaff />} />}
          />

          <Route
            path={routes.MAINTENANCE_STAFF_LIST_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_STAFF_PERMISSION]} component={<MaintenanceStaff />} />}
          />
          <Route
            path={`${routes.VIEW_MAINTENANCE_STAFF_LIST_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_STAFF_PERMISSION]} component={<ViewMaintenanceStaffDetails />} />}
          />
          <Route
            path={`${routes.EDIT_MAINTENANCE_STAFF_LIST_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_STAFF_PERMISSION]} component={<EditMaintenanceStaff />} />}
          />
          <Route
            path={routes.ADD_MAINTENANCE_STAFF_FORM_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_STAFF_PERMISSION]} component={<AddMaintenanceStaff />} />}
          />
          <Route path={`${routes.MAINTENANCE_STAFF_ID_CARD_ENDPOINT}/:id`} element={<MaintenanceStaffIdCard />} />
          <Route
            path={routes.AGENCY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_AGENCY_PERMISSION]} component={<Agency />} />}
          />
          <Route
            path={`${routes.VIEW_AGENCY_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_AGENCY_PERMISSION]} component={<ViewAgency />} />}
          />
          <Route
            path={routes.ADD_AGENCY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_AGENCY_PERMISSION]} component={<AddAgency />} />}
          />
          <Route
            path={`${routes.EDIT_AGENCY_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_AGENCY_PERMISSION]} component={<EditAgency />} />}
          />

          <Route
            path={routes.MAANGE_BULK_JOBS_LIST_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.BULK_INVOICES]} component={<ManageBulk />} />}
          />
          <Route
            path={routes.MAANGE_BULK_ADD_REQUEST_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.BULK_INVOICES]} component={<ManageBulkRequest />} />}
          />

          <Route
            path={routes.MANAGING_COMMITTEE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MANAGING_COMMITTEE_PERMISSION]} component={<ManagingCommittee />} />}
          />
          <Route
            path={routes.ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_ARCHIVE_MANAGING_COMMITTEE_MEMBER_PERMISSION]} component={<ArchiveCommitteeMember />} />}
          />
          <Route
            path={`${routes.VIEW_ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}/:id/:roleId`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_ARCHIVE_MANAGING_COMMITTEE_MEMBER_PERMISSION]} component={<ViewArchiveCommitteeMember />} />}
          />
          <Route
            path={routes.ADD_COMMITTEE_FORM_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_MANAGING_COMMITTEE_PERMISSION]} component={<AddCommittee />} />}
          />
          <Route
            path={`${routes.EDIT_COMMITTEE_FORM_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_MANAGING_COMMITTEE_PERMISSION]} component={<EditCommittee />} />}
          />
          <Route
            path={`${routes.VIEW_COMMITTEE_FORM_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MANAGING_COMMITTEE_PERMISSION]} component={<ViewManagingCommittee />} />}
          />
          <Route
            path={`${routes.RETIRE_COMMITTEE_MEMBER_FORM_ENDPOINT}/:id/:roleId`}
            element={<PermissionOnComponent permissionPaths={[permissions.RETIRE_MANAGING_COMMITTEE_PERMISSION]} component={<RetireManagingCommitteeMember />} />}
          />
          <Route
            path={`${routes.RESIGN_COMMITTEE_MEMBER_FORM_ENDPOINT}/:id/:roleId`}
            element={<PermissionOnComponent permissionPaths={[permissions.RESIGN_MANAGING_COMMITTEE_PERMISSION]} component={<ResignManagingCommittee />} />}
          />

          <Route
            path={`${routes.COMMITTEE_ACCESS_SETTINGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.UPDATE_ACCESS_SETTING_MANAGING_COMMITTEE_PERMISSION]} component={<CommitteeAccessSettings />} />}
          />
          <Route
            path={`${routes.VIEW_COMMITTEE_ACCESS_SETTINGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_ACCESS_SETTING_MANAGING_COMMITTEE_PERMISSION]} component={<ViewCommitteeAccessSettings />} />}
          />
          <Route
            path={`${routes.APPROVE_RESIGN_ENDPOINT}/:id/:roleId`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_MANAGING_COMMITTEE_PERMISSION]} component={<ApproveResign />} />}
          />
          <Route
            path={`${routes.REAPPOINT_MEMBER_ENDPOINT}/:id/:roleId`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_MANAGING_COMMITTEE_PERMISSION]} component={<ReappointMember />} />}
          />

          <Route
            path={`${routes.COMMITTEE_DETAILS_MEMBERS_AUDIT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MANAGING_COMMITTEE_AUDIT_LOGS_PERMISSION]} component={<CommitteeDetailsMembersAudit />} />}
          />
          <Route
            path={routes.COMMITTEE_DETAILS_AUDIT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MANAGING_COMMITTEE_AUDIT_LOGS_PERMISSION]} component={<CommitteeDetailsAudit />} />}
          />


          {/* Billing & Invoice Setup  */}

          {/* <Route path={routes.ACCOUNT_LEDGER_MAPPING_ENDPOINT} element={<AccountLedgerMapping />} /> */}
          <Route path={`${routes.ACCOUNT_LEDGER_MAPPING_ENDPOINT}/:id`} element={<AccountLedgerMapping />} />
          <Route path={routes.INVOICE_LIST_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE_TYPE_LIST]} component={<InvoiceList />} />} />
          <Route path={`${routes.EDIT_INVOICE_LIST_ENDPOINT}/:id`} element={<EditInvoiceSetting />} />
          {/* <Route path={routes.INVOICE_SETTINGS_FUNCTIONAL_SETTING_ENDPOINT} element={<InvoiceSettingFunctionalSettings />} /> */}
          {/* <Route path={routes.INVOICE_SETTINGS_TAXABLE_INVOICE_ENDPOINT} element={<InvoiceSettingTaxableFormat />} /> */}
          <Route path={`${routes.INVOICE_SETTINGS_TAXABLE_INVOICE_ENDPOINT}/:typeId`} element={<InvoiceSettingTaxableFormat />} />
          <Route path={`${routes.INVOICE_SETTINGS_TAXABLE_INVOICE_SUBHEAD_ENDPOINT}/:typeId/:id`} element={<TaxableFormatSubHeadList />} />
          {/* <Route path={routes.INVOICE_SETTINGS_DISPLAY_SETTINGS_ENDPOINT} element={<InvoiceSettingsDisplay />} />
          <Route path={routes.GST_AND_INVOICE_SETTINGS_ENDPOINT} element={<GstAndNocSetting />} /> */}
          {/* <Route path={routes.ADD_MORE_HEADS_ENDPOINT} element={<AddMoreHeads />} /> */}
          <Route path={`${routes.ADD_MORE_HEADS_ENDPOINT}/:typeId`} element={<AddMoreHeads />} />
          <Route path={`${routes.EDIT_MORE_HEADS_ENDPOINT}/:typeId/:id`} element={<EditMoreHeads />} />
          {/* <Route path={`${routes.INVOICE_SERIES_SETUP_ENDPOINT}`} element={<InvoiceSeriesSetup />} /> */}
          <Route path={`${routes.INVOICE_SERIES_SETUP_ENDPOINT}/:typeId`} element={<InvoiceSeriesSetup />} />
          <Route path={`${routes.DEBIT_NOTE_SETTINGS_ENDPOINT}/:id`} element={<DebitnoteSetting />} />
          <Route path={`${routes.CREDIT_NOTE_SETTINGS_ENDPOINT}/:id`} element={<CreditNoteSetting />} />
          {/* Desk Module/ Complaints */}
          <Route
            path={routes.COMPLAINT_DASHBOARD_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION]} component={<ComplaintDashboard />} />}
          />
          <Route
            path={routes.COMPLAINT_AGING_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION]} component={<ComplaintAging />} />}
          />
          <Route
            path={`${routes.ASSIGN_COMPLAINTS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.ASSIGNMENT_OF_COMPLAINT_PERMISSION]} component={<AssignComplaint />} />}
          />
          <Route
            path={`${routes.VIEW_COMPLAINTS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION]} component={<ViewComplaint />} />}
          />
          <Route
            path={`${routes.EDIT_COMPLAINTS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_COMPLAINT_DASHBOARD_PERMISSION]} component={<EditComplaint />} />}
          />
          <Route
            path={`${routes.PRINT_COMPLAINT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.PRINT_COMPLAINT_PERMISSION]} component={<Print />} />}
          />
          <Route
            path={routes.LOG_A_COMPLAINT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.LOG_A_COMPLAINT_PERMISSION]} component={<LogAComplaint />} />}
          />
          <Route
            path={routes.LOG_A_COMPLAINT_AUDIT_LOG_ENDPOINT}
            element={<LogAComplaintAuditLogs />}
          />
          <Route
            path={routes.ALL_DOCUMENTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_DOCUMENTS_PERMISSION]} component={<ComplaintAllDocuments />} />}
          />
          <Route
            path={routes.UPLOAD_DOCUMENT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.UPLOAD_DOCUMENTS_PERMISSION]} component={<UploadDocument />} />}
          />
          <Route
            path={`${routes.EDIT_DOCUMENT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_DOCUMENTS_PERMISSION]} component={<EditDocument />} />}
          />
          <Route
            path={routes.CREATE_FOLDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.CREATE_FOLDER_PERMISSION]} component={<CreateFolder />} />}
          />
          <Route
            path={routes.CREATE_SUBFOLDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.CREATE_FOLDER_PERMISSION]} component={<CreateSubFolder />} />}
          />
          <Route
            path={routes.MANAGE_COMPLAINT_CATEGORY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_COMPLAINT_CATEGORY_PERMISSION]} component={<ManageComplaintCategory />} />}
          />
          <Route
            path={routes.ADD_COMPLAINT_CATEGORY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_COMPLAINT_CATEGORY_PERMISSION]} component={<AddComplaintCategory />} />}
          />
          <Route
            path={`${routes.EDIT_COMPLAINT_CATEGORY_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_COMPLAINT_CATEGORY_PERMISSION]} component={<EditComplaintCategory />} />}
          />
          <Route path={routes.COMPLAINT_CATEGORY_AUDIT_LOG_ENDPOINT} element={<ComplaintCategoryAuditLogs />} />
          <Route
            path={routes.GENERAL_COMPLAINT_SETTINGS_ENDPOINT}
            element={
              <PermissionOnComponent
                permissionPaths={[
                  permissions.VIEW_COMPLAINT_SETTING_PERMISSION,
                  permissions.VIEW_COMPLAINT_ESCALATION_LEVEL_PERMISSION,
                  permissions.COMPLAINT_EMAIL_SMS_SETTING_PERMISSION,
                  permissions.COMPLAINT_TYPE_SETTING_PERMISSION,
                ]}
                component={<ComplaintGeneralSettings />}
              />}
          />
          <Route
            path={`${routes.EDIT_COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_COMPLAINT_ESCALATION_LEVEL_PERMISSION]} component={<EditComplaintEscalationLevelSetting />} />}
          />
          <Route path={`${routes.POST_COMMENTS_ENDPOINT}/:id`} element={<PostComment />} />
          {/* <Route path={routes.ALL_FOLDERS_ENDPOINT} element={<ComplaintAllFolders/>}/> */}
          {/* <Route path={routes.COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT} element={<ComplaintEscalationLevelSetting/>}/> */}

          {/* Finance */}

          <Route path={routes.MY_INVOICE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICES]} component={<MyInvoice />} />}
          />
          <Route path={`${routes.VIEW_MY_INVOICE_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICES]} component={<ViewInvoices />} />}
          />

          <Route path={routes.CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_DEBIT_NOTE_CAUSE]} component={<CreditDebitNoteCause />} />}
          />
          <Route path={`${routes.VIEW_NOTE_CAUSE_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_DEBIT_NOTE_CAUSE]} component={<ViewNoteCause />} />}
          />
          <Route path={routes.ADD_CAUSE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_DEBIT_NOTE_CAUSE]} component={<AddCause />} />}
          />
          <Route path={`${routes.EDIT_CAUSE_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.UPDATE_DEBIT_NOTE_CAUSE]} component={<EditCause />} />}
          />
          <Route path={`${routes.CREDIT_DEBIT_NOTE_CAUSE_AUDIT_LOGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_AUDIT_LOG_DEBIT_NOTE_CAUSE]} component={<CreditDebitAuditLog />} />}
          />
          <Route path={routes.CREDIT_DEBIT_NOTE_CAUSE_ALL_AUDIT_LOGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_AUDIT_LOG_DEBIT_NOTE_CAUSE]} component={<CreditDebitAllAuditLog />} />}
          />
          <Route path={routes.DUE_REMINDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_DUE_REMINDER, permissions.FD_MATURITY_REMINDER, permissions.NOMINEE_REMINDER, permissions.MEMBER_DUE_REMINDER]} component={<DueReminder />} />}
          />
          <Route path={routes.DEPOSIT_DUE_REMINDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_DUE_REMINDER]} component={<DepositDueRemider />} />}
          />
          <Route path={routes.INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES}
            element={
              <PermissionOnComponent
                permissionPaths={[
                  permissions.VIEW_MASTER_TARIFF_SETTINGS,
                ]}
                component={<InvoiceDetails />}
              />} />


          <Route path={routes.MEMBER_DUE_REMINDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.MEMBER_DUE_REMINDER]} component={<MemberDueReminder />} />}
          />
          <Route path={routes.FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.FD_MATURITY_REMINDER]} component={<FixedDepositMaturityReminder />} />}
          />
          <Route path={routes.NOMINATION_REMINDER_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.NOMINEE_REMINDER]} component={<NominationReminder />} />}
          />

          <Route path={routes.MANAGE_ADVANCE_NOTE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_ADVANCE_NOTE]} component={<AdvanceNote />} />}
          />
          <Route path={routes.PRINT_MANAGE_ADVANCE_NOTE_ENDPOINT} element={<PrintAdvanceNoteList />} />
          <Route path={routes.ADD_ADVANCE_NOTE_ENDPOINT} element={<AddAdvanceNote />} />
          <Route path={routes.UPLOAD_ADVANCE_NOTE_ENDPOINT} element={<UploadAdvanceNote />} />
          <Route path={`${routes.EDIT_ADVANCE_NOTE_ENDPOINT}/:id`} element={<EditAdvanceNote />} />
          <Route path={`${routes.PRINT_ADVANCE_NOTE_RECIEPT_ENDPOINT}/:id`} element={<PrintAdvanceNoteReciept />} />

          <Route path={routes.DEBIT_NOTE_HISTORY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.HISTORY_DEBIT_NOTE]} component={<DebitNoteHistory />} />}
          />
          <Route path={routes.PRINT_DEBIT_NOTE_HISTORY_ENDPOINT} element={<PrintDebitNoteHistory />} />
          <Route path={routes.DELETE_RECEIPT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.DELETE_RECEIPT]} component={<DeleteReceipts />} />}
          />
          <Route path={`${routes.VIEW_DELETED_RECEIPT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_DELETED_RECEIPT]} component={<ViewDeletedReceipt />} />}
          />
          <Route path={routes.CHEQUE_DEPOSIT_SLIP_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.CHEQUE_REPORTS]} component={<ChequeDepositSlip />} />}
          />
          <Route path={routes.COUNTED_INVOICE_REPORT_ENDPOINT} element={<CountedInvoiceReport />} />
          <Route path={routes.PRINT_CHEQUE_DEPOSIT_SLIP_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.CHEQUE_REPORTS]} component={<PrintChequeDepositSlip />} />}
          />

          <Route
            path={routes.MANAGE_INVOICE_CURRENT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE]} component={<ManageInvoiceCurrent />} />}
          />
          <Route path={routes.MANAGE_INVOICE_PREVIOUS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE]} component={<ManageInvoicePrevious />} />}
          />
          <Route path={routes.MANAGE_INVOICE_DRAFTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE]} component={<ManageInvoiceDraft />} />} />
          <Route path={`${routes.VIEW_MANAGE_INVOICE_CURRENT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE_RECEIPT]} component={<ViewManageInvoices />} />} />
          <Route path={`${routes.VIEW_MANAGE_INVOICE_PREVIOUS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_INVOICE_RECEIPT]} component={<ViewManagePreviousInvoices />} />} />

          <Route path={routes.UPLOAD_PAYMENT_EXCEL_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_MAKE_PAYMENT]} component={<UploadPaymentExcel />} />}
          />
          <Route path={routes.MULTI_PAYMENT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_MAKE_PAYMENT]} component={<MakeMultiPayment />} />} />
          <Route path={`${routes.PRINT_INVOICE_RECEIPT_ENDPOINT}/:receiptid`}
            element={<PermissionOnComponent permissionPaths={[permissions.PRINT_RECIEPT, permissions.PRINT_RECEIPT]} component={<PrintInvoiceReceipt />} />} />
          <Route path={`${routes.PRINT_INVOICE_BILL_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.PRINT_INVOICE]} component={<PrintInvoiceBill />} />} />
          <Route path={`${routes.PRINT_INVOICE_BILL_MULTI_ENDPOINT}`} element={<PermissionOnComponent permissionPaths={[permissions.PRINT_INVOICE]} component={<PrintInvoiceBillMulti />} />} />
          <Route path={`${routes.PRINT_INVOICE_RECEIPT_MULTI_ENDPOINT}`} element={<PermissionOnComponent permissionPaths={[permissions.PRINT_RECIEPT]} component={<PrintInvoiceReceiptMulti />} />} />

          <Route path={routes.GENERATE_INVOICE_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.GENERATE_INVOICE]} component={<GenerateInvoice />} />} />
          <Route path={routes.GENERATE_CURRENT_INVOICE_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.GENERATE_INVOICE]} component={<GenerateCurrentInvoice />} />} />
          <Route path={routes.UPLOAD_GENERATE_INVOICE_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.GENERATE_INVOICE]} component={<GenerateUploadExcelFormat />} />} />

          <Route path={`${routes.EDIT_INVOICE_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.EDIT_INVOICE]} component={<EditInvoice />} />} />
          <Route path={`${routes.EDIT_DRAFT_INVOICE_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.EDIT_DRAFT_INVOICE]} component={<EditInvoiceDraft />} />} />
          <Route path={`${routes.EDIT_DRAFT_INVOICE_ENDPOINT_MULTI}`} element={<PermissionOnComponent permissionPaths={[permissions.EDIT_DRAFT_INVOICE]} component={<EditInvoiceDraftMulti />} />} />

          <Route path={routes.RECEIPTS_TRANSACTIONS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TRANSACIION_HISTORY_INVOICE]} component={<ReceiptsTransactions />} />}
          />
          <Route path={`${routes.VIEW_RECEIPTS_TRANSACTIONS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TRANSACIION_HISTORY_INVOICE]} component={<ViewReceiptTransaction />} />}
          />
          <Route path={`${routes.PRINT_RECEIPTS_TRANSACTIONS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TRANSACIION_HISTORY_INVOICE]} component={<PrintReceiptTransactions />} />}
          />
          <Route path={routes.INCOMPLETE_TRANSACTIONS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_TRANSACIION_HISTORY_INVOICE]} component={<IncompleteTransactions />} />}
          />
          <Route path={`${routes.VIEW_INCOMPLETE_TRANSACTIONS_ENDPOINT}/:id`} element={<ViewIncompleteTransactions />} />
          <Route path={routes.MEMBER_DUE_SUMMARY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.MEMBER_DUE_REPORTS]} component={<MemberDueSummary />} />}
          />
          <Route path={routes.PRINT_MEMBER_DUE_SUMMARY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.MEMBER_DUE_REPORTS]} component={<PrintMemberDueSummary />} />}
          />
          <Route path={routes.MEMBER_BILL_DUE_DETAILS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.MEMBER_DUE_REPORTS]} component={<MemberBillDueReport />} />}
          />
          <Route path={routes.PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.MEMBER_DUE_REPORTS]} component={<PrintMemberBillDueReport />} />}
          />
          <Route path={routes.OPENING_BALANCE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.OPENING_BALANCE_REPORTS]} component={<OpeningBalance />} />}
          />

          <Route path={`${routes.MYINVOICE_MAKE_PAYMENT_ENDPOINT}/:id`} element={<MyInvoicePayment />} />
          <Route path={`${routes.MAKE_PAYMENT_ENDPOINT}/:id`} element={<MakePaymentS />} />
          <Route path={`${routes.PAYMENT_AND_ADJUSTMENT_ENDPOINT}/:id`} element={<PaymentAndAdjust />} />
          <Route path={`${routes.EDIT_PAYMENT_ENDPOINT}/:id`} element={<EditPaymentS />} />
          <Route path={`${routes.EDIT_RECEIPT_ENDPOINT}/:id`} element={<EditReceipt />} />

          <Route path={routes.PAYMENT_SUCCESS_ENDPOINT} element={<PaymentSuccess />} />

          <Route path={routes.MANAGE_DEBIT_CURRENT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_DEBIT_NOTE]} component={<ManageDebitNoteCurrent />} />}
          />
          <Route path={routes.MANAGE_DEBIT_PREVIOUS_ENDPOINT} element={<ManageDebitPrevious />} />
          <Route path={routes.MANAGE_DEBIT_DRAFT_ENDPOINT} element={<ManageDebitDraft />} />
          <Route path={routes.ADD_DEBIT_NOTE_ENDPOINT} element={<AddDebitNote />} />
          <Route path={routes.ADD_MULTIPLE_DEBIT_NOTE_ENDPOINT} element={<MultipleDebitNoteDetail />} />
          <Route path={`${routes.VIEW_MANAGE_DEBIT_NOTE_ENDPOINT}/:id`} element={<ViewDebitNoteDetails />} />
          <Route path={`${routes.VIEW_MANAGE_DEBIT_NOTE_PREVIOUS_ENDPOINT}/:id`} element={<ViewDebitNotePrevious />} />
          <Route path={`${routes.EDIT_MANAGE_DEBIT_NOTE_ENDPOINT}/:id`} element={<EditDebitNote />} />
          <Route path={`${routes.EDIT_MANAGE_DEBIT_NOTE_DRAFT_ENDPOINT}/:id`} element={<EditDebitNoteDraft />} />
          <Route path={`${routes.EDIT_MANAGE_DEBIT_NOTE_DRAFT_ENDPOINT}`} element={<EditDebitNoteDraft />} />
          <Route path={`${routes.PAYMENT_AND_ADJUSTMENT_DEBIT_NOTE_ENDPOINT}/:id`} element={<PaymentAndAdjustment />} />
          <Route path={`${routes.MAKE_PAYMENT_DEBIT_NOTE_ENDPOINT}/:id`} element={<MakePaymentDebitNote />} />
          <Route path={`${routes.EDIT_PAYMENT_DEBIT_NOTE_ENDPOINT}/:id`} element={<EditPaymentDebitNote />} />
          <Route path={`${routes.MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT}`} element={<MakeMultiPaymentDebitNote />} />
          <Route path={`${routes.MAKE_PAYMENT_SUCCESS_DEBIT_NOTE_ENDPOINT}`} element={<MakePaymentSuccessDebitNote />} />
          <Route path={`${routes.PRINT_DEBIT_NOTE_INVOICE_ENDPOINT}/:id`} element={<PrintDebitBill />} />
          <Route path={`${routes.PRINT_DEBIT_NOTE_INVOICE_MULTI_ENDPOINT}`} element={<PrintDebitBill />} />
          <Route path={`${routes.PRINT_DEBIT_NOTE_RECEIPT_ENDPOINT}/:id`} element={<PrintDebitReceipt />} />
          <Route path={`${routes.PRINT_DEBIT_NOTE_RECEIPT_MULTI_ENDPOINT}`} element={<PrintDebitReceipt />} />


          <Route path={routes.MANAGE_CREDIT_NOTE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_CREDIT_NOTE]} component={<ManageCreditNote />} />}
          />
          <Route path={routes.ADD_CREDIT_NOTE_ENDPOINT} element={<AddCreditNote />} />
          <Route path={`${routes.EDIT_CREDIT_NOTE_ENDPOINT}/:id`} element={<EditCreditNote />} />
          <Route path={routes.UPLOAD_CREDIT_NOTE_ENDPOINT} element={<UploadCreditNote />} />
          <Route path={`${routes.CREDIT_NOTE_PAYMENT_ENDPOINT}/:id`} element={<CreditPayment />} />
          <Route path={`${routes.PRINT_CREDIT_NOTE_RECIEPT_ENDPOINT}/:id`} element={<PrintCreditNoteReciept />} />



          {/* Bulk Uploads */}
          <Route
            path={routes.BULK_UPLOAD_Family_ENDPOINT}
            element={<BulkUploadFamily />}
          />
          <Route
            path={routes.BULK_UPLOAD_OCCUPANTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_OCCUPANTS_PERMISSION]} component={<BulkUploadOccupants />} />}
          />
          <Route
            path={routes.UPDATE_MEMBED_EXCEL_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_OCCUPANTS_PERMISSION]} component={<UpdateMemberExcel />} />}
          />
          <Route
            path={routes.BULK_UPLOAD_TENANTS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.BULK_UPLOAD_TENANTS_PERMISSION]} component={<BulkUploadTenants />} />}
          />
          <Route
            path={routes.BULK_UPLOAD_STAFF_PERSONNEL_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.BULK_UPLOAD_STAFF_PERSONNEL_PERMISSION]} component={<BulkUploadStaffPersonnel />} />}
          />
          <Route
            path={routes.BULK_UPLOAD_SOCIETY_PARKING_DETAILS}
            element={<BulkUploadsSocietyParkingDetails />}
          />
          <Route
            path={routes.BULK_UPLOAD_OPENING_BALANCE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.BULK_UPLOAD_OPENING_BALANCE_PERMISSION]} component={<BulkUploadOpeningBalance />} />}
          />


          {/* society profile setup  */}

          <Route path={routes.REVIEW_ENDPOINT} element={<Reviews />} />
          <Route path={routes.CONTENT_MANAGEMENT_TEMPLATE} element={<ContentManagementTemplate />} />
          <Route path={`${routes.VIEW_CONTENT_MANAGEMENT_TEMPLATE}/:id`} element={<ViewContentManagment />} />
          <Route path={`${routes.EDIT_CONTENT_MANAGEMENT_TEMPLATE}/:id`} element={<EditContentManagment />} />
          <Route path={`${routes.AUDIT_LOGS_CONTENT_MANAGEMENT_TEMPLATE}/:id`} element={<ContentManagmentTemplateAudit />} />
          <Route path={routes.SMS_CREDIT_PACKAGES_ENDPOINT} element={<SmsCreditPackages />} />
          <Route path={routes.SMS_CREDIT_ORDER_HISTORY_ENDPOINT} element={<SmsCreditOrderHistory />} />
          <Route path={routes.VIEW_SMS_CREDIT_ORDER_HISTORY_ENDPOINT} element={<ViewSmsOrder />} />
          <Route path={routes.SMS_HISTORY_LIST_ENDPOINT} element={<SmsHistoryList />} />

          {/* Manage Roles and Rights  */}
          <Route
            path={routes.MANAGE_EMPLOYEE_ROLES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_ROLE_PERMISSION]} component={<ManageEmployeeRoles />} />}
          />
          <Route
            path={routes.ADD_EMPLOYEE_ROLES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_EMPLOYEE_ROLE_PERMISSION]} component={<AddEmployeeRoles />} />}
          />
          <Route
            path={`${routes.VIEW_EMPLOYEE_ROLES_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_EMPLOYEE_ROLE_PERMISSION]} component={<ViewEmployeeRoles />} />}
          />
          <Route
            path={`${routes.EDIT_EMPLOYEE_ROLES_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_EMPLOYEE_ROLE_PERMISSION]} component={<EditEmployeeRoles />} />}
          />

          <Route path={routes.MANAGE_OCCUPANT_ROLES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions?.VIEW_MEMBER_TITLE]} component={<ManageoccupantsRolesTable />} />}
          />
          <Route path={routes.ADD_OCCUPANT_ROLES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions?.ADD_MEMBER_TITLE]} component={<AddOccupantRoles />} />}
          />
          <Route path={`${routes.VIEW_OCCUPANT_ROLES_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions?.VIEW_MEMBER_TITLE]} component={<ViewOccupantRoles />} />}
          />
          <Route path={`${routes.EDIT_OCCUPANT_ROLES_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions?.EDIT_MEMBER_TITLE]} component={<EditOccupantRoles />} />}
          />
          <Route path={routes.MANAGE_SOCIETY_ROLES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[""]} component={<ManageSocietyRoles />} />}
          />
          <Route path={routes.MANAGE_FAMILY_ROLES_ENDPOINT} element={<ManageFamilyRoles />} />
          <Route path={routes.ADD_FAMILY_ROLES_ENDPOINT} element={<AddFamilyRoles />} />
          <Route path={`${routes.VIEW_FAMILY_ROLES_ENDPOINT}/:id`} element={<ViewFamilyRoles />} />
          <Route path={`${routes.EDIT_FAMILY_ROLES_ENDPOINT}/:id`} element={<EditFamilyRoles />} />
          <Route
            path={routes.SOCIETY_FORMS_AND_BYLAWS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_SOCIETY_FORMS_AND_BYE_LAWS_PERMISSION]} component={<SocietyFormsandBylaws />} />}
          />


          {/* reports  */}

          <Route path={routes.SOCIETY_EMAIL_REPORT_ENDPOINT} element={<SocietyEmailReports />} />
          <Route path={routes.MEMBER_LEDGER_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MEMBER_LEDGER_REPORT_PERMISSION]} component={<MemberLedgerReports />} />}
          />
          <Route path={routes.NDC_DETAIL}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_MEMBER_LEDGER_REPORT_PERMISSION]} component={<PrintNDC />} />}
          />
          <Route path={routes.SOCIETY_PARKING_REPORT_ENDPOINT} element={<SocietyParkingReports />} />
          <Route path={routes.PRINT_SOCIETY_PARKING_REPORT_ENDPOINT} element={<PrintSocietyParkingReport />}
          />
          <Route path={routes.SOCIETY_HISTORY_REPORT_ENDPOINT}
            element={<SocietyDownloadHistory />} />


          {/* finance report */}
          <Route path={routes.DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_REPORT_DEBIT_NOTE]} component={<DebitNoteInvoiceReport />} />
            } />
          <Route path={routes.PRINT_DEBIT_NOTE_INVOICE_REPORT_ENDPOINT} element={<PrintDebitNoteInoviceReport />} />
          <Route path={routes.INVOICE_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_REPORTS]} component={<InvoiceReport />} />}
          />
          <Route path={routes.PRINT_INVOICE_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_REPORTS]} component={<PrintInoviceReport />} />}
          />
          <Route path={routes.RECEIPT_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.RECEIPT_REPORTS]} component={<ReceiptReport />} />}
          />
          <Route path={routes.PRINT_RECEIPT_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.RECEIPT_REPORTS]} component={<PrintReceiptReport />} />}
          />
          <Route path={routes.SUPPLEMENTARY_BILL_REPORT_ENDPOINT} element={<SupplementaryBillReport />} />
          <Route path={routes.PRINT_SUPPLEMENTARY_BILL_REPORT_ENDPOINT} element={<PrintSupplementaryBillReport />} />
          <Route path={routes.DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.RECEIPT_REPORT_DEBIT_NOTE]} component={<DebitNoteReceiptReport />} />}
          />
          <Route path={routes.PRINT_DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT} element={<PrintDebitNoteReceiptReport />} />
          <Route path={routes.DELETED_INVOICE_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.DELETED_INVOICE_REPORT]} component={<DeletedInvoiceReport />} />}
          />
          <Route path={`${routes.PRINT_DELETED_INVOICE_REPORT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.DELETED_INVOICE_REPORT]} component={<PrintDeletedInvoiceReport />} />}
          />
          <Route path={routes.DELETED_DEBIT_NOTE_REPORT_ENDPOINT} element={<DeletedDebitNoteReport />} />
          <Route path={`${routes.PRINT_DELETED_DEBIT_NOTE_REPORT_ENDPOINT}/:id`} element={<PrintDeletedDebitNoteReport />} />
          <Route path={routes.INVOICE_INTEREST_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INTEREST_CALCULATION_REPORTS]} component={<InvoiceInterestReport />} />}
          />
          <Route path={routes.PREV_INVOICE_INTEREST_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INTEREST_CALCULATION_REPORTS]} component={<PrevInvoiceInterestReport />} />}
          />
          <Route path={routes.INTEREST_CALCULATION_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INTEREST_CALCULATION_REPORTS]} component={<InterestCalculationReport />} />}
          />
          <Route path={`${routes.PRINT_INTEREST_CALCULATION_REPORT_ENDPOINT}`} element={<PrintInterestCalculationReport />} />
          <Route path={routes.RECONCILIATION_REPORT_ENDPOINT} element={<ReconciliationReport />} />
          <Route path={`${routes.PRINT_RECONCILIATION_REPORT_ENDPOINT}`} element={<PrintReconciliationReport />} />
          <Route path={routes.DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INTEREST_CALCULATION_REPORT_DEBIT_NOTE]} component={<DebitNoteInterestCalculationReport />} />}
          />
          <Route path={`${routes.PRINT_DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT}`} element={<PrintDebitNoteInterestCalculationReport />} />
          <Route path={routes.INVOICE_AGING_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.INVOICE_AGING_REPORT]} component={<InvoiceAgingReport />} />}
          />
          <Route path={routes.INVOICE_AUDIT_REPORT_ENDPOINT} element={<InvoiceAuditReport />}
          />
          <Route path={routes.E_INVOICE_REPORT_ENDPOINT} element={<EInvoiceReport />}
          />
          <Route path={routes.GST_REPORT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.GST_REPORTS]} component={<GstReport />} />}
          />
          {/* gate management  */}
          <Route path={routes.VISITOR_DASHBOARD_ENDPOINT} element={<VisitorDashboard />} />
          <Route
            path={routes.PRINT_VISITOR__LOGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_VISITOR_LOG_PERMISSION]} component={<PrintVisitorLog />} />}
          />


          <Route
            path={routes.MANAGE_PARKING_TYPE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_PARKING_TYPE_IN_OCCUPANTS_PERMISSION]} component={<ManageParkingType />} />}
          />
          <Route
            path={routes.SOCIETY_PARKING_CHARGES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_PARKING_CHARGES_PERMISSION]} component={<SocietyParkingCharges />} />}
          />
          <Route
            path={routes.EDIT_SOCIETY_PARKING_CHARGES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_PARKING_CHARGES_PERMISSION]} component={<EditSocietyParkingCharges />} />}
          />
          <Route
            path={routes.AUDIT_LOG_SOCIETY_PARKING_CHARGES_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_PARKING_CHARGE_AUDIT_PERMISSION]} component={<ParkingChargesAuditLogs />} />}
          />
          <Route
            path={routes.VEHICLE_DASHBOARD_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_VEHICLE_IN_OUT_PERMISSION]} component={<VehicleDashboard />} />}
          />
          <Route
            path={routes.PRINT_VISITOR_VEHICLE_LOGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_VEHICLE_IN_OUT_PERMISSION]} component={<PrintVehicleLogs />} />}
          />
          {/* club- facility  */}
          <Route
            path={routes.VIEW_BOOKINGS_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_BOOKING_PERMISSION, permissions.VIEW_OWN_BOOKING_PERMISSION]} component={<ViewBookings />} />}
          />
          <Route
            path={`${routes.VIEW_BOOKINGS_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_BOOKING_PERMISSION, permissions.VIEW_OWN_BOOKING_PERMISSION]} component={<ViewBookingDetails />} />}
          />
          <Route
            path={`${routes.MAKE_PAYMENT_FOR_BOOKINGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.MAKE_BOOKING_PAYMENT_PERMISSION, permissions.MAKE_OWN_BOOKING_PAYMENT_PERMISSION]} component={<MakePaymentForBooking />} />}
          />
          <Route
            path={`${routes.MAKE_PAYMENT_BOOKINGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.MAKE_BOOKING_PERMISSION, permissions.MAKE_OWN_BOOKING_PAYMENT_PERMISSION]} component={<MakePaymentBookings />} />}
          />
          {/* <Route
            path={`${routes.PRINT_BOOKING_RECIEPT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.PRINT_BOOKING_INVOICE_PERMISSION]} component={<PrintBookingReciept />} />}
          /> */}
          <Route
            path={`${routes.PRINT_BOOKING_INVOICE_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.PRINT_BOOKING_INVOICE_PERMISSION]} component={<PrintBookingInvoice />} />}
          />
          <Route
            path={`${routes.BOOKING_PAYMENT_AND_ADJUSTMENT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.MAKE_BOOKING_PAYMENT_PERMISSION]} component={<BookingPaymentAndAdjust />} />}
          />

          <Route path={routes.FACILITY_REPORT_ENDPOINT} element={<FacilityReport />} />
          <Route path={routes.ALL_FACILITY_AUDIT_LOG_ENDPOINT} element={<FacilityAuditLogs />} />
          <Route path={routes.PRINT_FACILITY_REPORT_ENDPOINT} element={<PrintFacilityReport />} />
          <Route
            path={routes.ALL_FACILITY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_FACILITY_PERMISSION]} component={<AllFacility />} />}
          />
          <Route
            path={routes.ADD_FACILITY_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_FACILITY_PERMISSION]} component={<AddFacility />} />}
          />
          <Route
            path={`${routes.VIEW_FACILTIY_DETAILS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_FACILITY_PERMISSION]} component={<ViewFacilityDetails />} />}
          />
          <Route
            path={`${routes.EDIT_FACILITY_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_FACILITY_PERMISSION]} component={<EditFacility />} />}
          />
          <Route
            path={`${routes.MAKE_BOOKING_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.MAKE_BOOKING_PERMISSION]} component={<MakeBooking />} />}
          />
          <Route
            path={routes.ADD_TIME_SLOT_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_TIME_SLOT_PERMISSION]} component={<AddTimeSlot />} />}
          />
          <Route
            path={routes.VIEW_TIME_SLOT_TYPE_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_TIME_SLOT_PERMISSION]} component={<TimeSlotTypeList />} />}
          />
          <Route
            path={routes.ADD_RATE_CHART_ENDPOINT}
            element={<PermissionOnComponent permissionPaths={[permissions.ADD_RATE_CHART_PERMISSION]} component={<AddRateChart />} />}
          />
          <Route
            path={`${routes.EDIT_RATE_CHART_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_RATE_CHART_PERMISSION]} component={<EditRateChart />} />}
          />
          <Route
            path={`${routes.EDIT_TIME_SLOT_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.EDIT_TIME_SLOT_PERMISSION]} component={<EditTimeSlot />} />}
          />
          <Route
            path={`${routes.VIEW_FACILTIY_AUDIT_LOGS_ENDPOINT}/:id`}
            element={<PermissionOnComponent permissionPaths={[permissions.VIEW_FACILITY_PERMISSION]} component={<ViewFacilityAuditLogs />} />}
          />

          {/* Compliance  */}
          <Route path={routes.I_FORM_ENDPOINT} element={<IForm />} />
          <Route path={routes.PRINT_I_FORM_ENDPOINT} element={<PrintIForm />} />
          <Route path={routes.J_FORM_ENDPOINT} element={<JForm />} />
          <Route path={routes.PRINT_J_FORM_ENDPOINT} element={<PrintJForm />} />
          <Route path={routes.SHARE_REGISTER_ENDPOINT} element={<PrintShareRegister />} />
          <Route path={routes.PROPERTY_REGISTER_ENDPOINT} element={<PrintPropertyRegister />} />
          <Route path={routes.NOMINATION_REGISTER_ENDPOINT} element={<PrintNominationRegister />} />
          <Route path={routes.LIEN_MORTGAGE_REGISTER_ENDPOINT} element={<LienMortgageRegister />} />
          <Route path={routes.COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT} element={<DocumentsIssued />} />
          <Route path={routes.ISSUE_DOCUMENT_ENDPOINT} element={<IssueDocument />} />
          <Route path={`${routes.VIEW_COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT}/:id`} element={<ViewDocIssued />} />
          <Route path={`${routes.EDIT_COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT}/:id`} element={<EditDocIssued />} />
          <Route path={routes.SAVED_NOTICE_ENDPOINT} element={<SavedNotice />} />
          <Route path={`${routes.VIEW_SAVED_NOTICE_ENDPOINT}/:status/:id`} element={<ViewSavedNotice />} />
          <Route path={`${routes.EDIT_SAVED_NOTICE_ENDPOINT}/:id`} element={<EditSavedNotice />} />
          <Route path={`${routes.AUDIT_LOGS_SAVED_NOTICE_ENDPOINT}/:id`} element={<AuditLogSavedNotice />} />
          <Route path={routes.SAVED_NOTICE_SENT_MINUTES_ENDPOINT} element={<SentMinutes />} />

          <Route path={routes.SENT_NOTICE_ENDPOINT} element={<SentNotice />} />
          <Route path={routes.SENT_NOTICE_SENT_MINUTES_ENDPOINT} element={<SentNoticeSentMinutes />} />
          <Route path={`${routes.VIEW_SENT_NOTICE_ENDPOINT}/:id`} element={<ViewSentNotice />} />
          <Route path={`${routes.RESCHEDULE_SENT_NOTICE_ENDPOINT}/:id`} element={<RescheduleSentNotice />} />
          <Route path={`${routes.AUDIT_LOGS_SENT_NOTICE_ENDPOINT}/:id`} element={<AuditLogSentNotice />} />

          <Route path={routes.SCHEDULE_A_MEETING_ENDPOINT} element={<ScheduleAMeeting />} />
          <Route path={routes.SELECT_MINUTES_FOR_MINUTES_ENDPOINT} element={<SelectMemberForMinutes />} />
          <Route path={routes.CREATE_NOTICE_FOR_SCHEDULE_MEETING_ENDPOINT} element={<CreateNotice />} />

          <Route path={routes.CREATE_MINUTES_FOR_MEETINGS_ENDPOINT} element={<CreateMinutesForMeetings />} />
          <Route path={routes.CREATE_MINUTES_MEETINGS_ENDPOINT} element={<CreateMinutes />} />

          <Route path={routes.SAVED_MINUTES_ENDPOINT} element={<SavedMinutes />} />
          <Route path={`${routes.VIEW_SAVED_MINUTES_ENDPOINT}/:id`} element={<ViewSavedMinutes />} />

          <Route path={routes.TRANSFER_FLAT_DOCUMENTS_ENDPOINT} element={<TransferFlat />} />
          <Route path={routes.FLAT_TRANSFER_DOCUMENTS_ENDPOINT} element={<FlatDocument />} />
          <Route path={routes.TRANSFER_FLAT_FORM_DOCUMENTS_ENDPOINT} element={<TransferFlatForm />} />

          {/* ---------------Reports ------------------------------ */}

          <Route path={routes.VIEW_BILL_GENERATION_ENDPOINT} element={<ViewBillGeneration />} />
          <Route path={routes.BILL_REPORTS_ENDPOINT} element={<BillReports />} />
          <Route path={routes.ACCOUNT_REPORTS_ENDPOINT} element={<AccountReports />} />
          <Route path={routes.FINANCE_REPORTS_ENDPOINT} element={<OutstandingReport />} />

          {/* social and communications  */}

          <Route path={routes.PHOTO_GALLERY_ENDPOINT} element={<PhotoGallery />} />Pnotice
          <Route path={routes.ALBUM_PHOTOS_ENDPOINT} element={<AlbumPhotos />} />
          <Route path={routes.NOTICE_AND_CIRCULARS_ENDPOINT} element={<NoticeAndCircular />} />
          <Route path={routes.ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT} element={<NoticeAndCircularArchive />} />
          <Route path={routes.DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.VIEW_NOTICE_CIRCULAR_DRAFTS_PERMISSION]} component={<DraftNoticeAndCircular />} />} />
          <Route path={`${routes.VIEW_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.VIEW_NOTICE_CIRCULAR_PERMISSION]} component={<ViewNotice />} />} />
          <Route path={`${routes.PRINT_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.PRINT_NOTICE_CIRCULAR_PERMISSION]} component={<PrintNoticeAndCircular />} />} />
          <Route path={`${routes.VIEW_ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.VIEW_NOTICE_CIRCULAR_ARCHIVES_PERMISSION]} component={<ViewNoticeCircularArchive />} />} />
          <Route path={`${routes.ACKNOWLEDGMENT_LIST_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<AcknowledgementListNotice />} />
          <Route path={routes.CREATE_NOTICE_AND_CIRCULARS_ENDPOINT} element={<PermissionOnComponent permissionPaths={[permissions.CREATE_NOTICE_CIRCULAR_PERMISSION]} component={<CreateNoticeandCircular />} />} />
          <Route path={routes.NOTICE_AND_CIRCULARS_AUDIT_LOG_ENDPOINT} element={<NoticeAndCircularAuditLogs />} />
          <Route path={`${routes.EDIT_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<PermissionOnComponent permissionPaths={[permissions.EDIT_NOTICE_CIRCULAR_PERMISSION]} component={<CreateNoticeandCircular edit={true} />} />} />
          <Route path={`${routes.EDIT_DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT}/:id`} element={<CreateNoticeandCircular edit={true} draft={true} />} />

          <Route path={routes.TEST_ENDPOINT} element={<CreditNoteandAdvance />} />
        </Route>

        <Route path='*' element={<>not found</>} />
      </Routes>
    </div>
  );
};

export default Routers;
