import * as Yup from "yup";
import "yup-phone-lite";
import { checkFileSizeLessThan2 } from "../../utils/helpers/universalFunctions";

Yup.addMethod(Yup.array, "checkUnique", function (propertyName, message) {
  return this.test("unique", message, function (list) {
    const uniqueSet = new Set();
    const { path } = this;
    list.forEach((item, index) => {
      if (uniqueSet.has(item[`${propertyName}`])) {
        throw this.createError({
          path: `${path}[${index}].${propertyName}`,
          message,
        });
        // return false;
      } else {
        uniqueSet.add(item[`${propertyName}`]);
      }
    })
    return true;
  });
}
);


export const SendEmailValidation = () => {
  return Yup.object({
    subject: Yup.string().trim().required('*Subject is required').min(3, '*Subject must contain min 3 letters'),
    message: Yup.string().trim().required('*Message is required'),
  })
}

export const MemberPrivacyValidation = () => {
  return Yup.object({
    memberPrivacy: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          number: Yup.string(),
          email: Yup.string(),
          dob: Yup.string(),
          staff: Yup.string(),
        })
      )
  });
}

export const AddOccupantsValidation = () => {
  return Yup.object({
    titleId: Yup.string().required('*Required'),
    prefix: Yup.string().required('*Required'),
    firstName: Yup.string().required('*Required').min(3, '*Must contain min 3 letters').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    middleName: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').min(3, '*Must contain min 3 letters').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    gender: Yup.string().required('*Required'),
    registerAs: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {

        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    secondaryMobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test({
            test: secondaryMobileNo => !secondaryMobileNo?.length,
            message: '*Please select country first'
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      }),
    mobileVisible: Yup.string(),
    sendeSMSOnAlternateNo: Yup.string(),
    primaryEmail: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    secondaryEmail: Yup.string()
      .when({
        is: (val) => !!val?.length,
        then: () => Yup.string()
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address")
          .notOneOf([Yup.ref('primaryEmail'), null], '*Email should be different from primary'),
      }),
    emailVisible: Yup.string(),
    loginAccess: Yup.string(),
    sendeSMSOnAlternateEmail: Yup.string(),
    adharCardNo: Yup.string().test('not-only-zeros', '*Invalid identity proof number', value => {
      return !/^0/.test(value);
    }),
    adharFile: Yup.string(),
    wingId: Yup.string().required('*Required'),
    flatId: Yup.string().required('*Required'),
    profilePic: Yup.string()
  });
};

export const AddExistingOccupantsValidation = () => {
  return Yup.object({
    titleId: Yup.string().required('*Title is required'),
    wingId: Yup.string().required('*Wing is required'),
    flatId: Yup.string().required('*Flat is required'),
    registerAs: Yup.string(),
  })
}

export const AddTenantValidation = () => {
  return Yup.object({
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    middleName: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    secondaryMobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test({
            test: secondaryMobileNo => !secondaryMobileNo?.length,
            message: '*Please select country first'
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      }),
    primaryEmail: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    address: Yup.string().trim().required('*Required'),
    wingId: Yup.string().required('*Required'),
    flatId: Yup.string().required('*Required'),
    adharCardNo: Yup.string().required('*Required').test('not-only-zeros', '*Invalid identity proof number', value => {
      return !/^0/.test(value);
    }),
    adharFile: Yup.string(),
    panCardNo: Yup.string().required('*Required').min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
      }),
    gstRegistrationNo: Yup.string().test('not-only-zeros', '*Invalid account number', value => {
      return !/^0/.test(value);
    }),
    showInBill: Yup.string(),
    rentDeedStartDate: Yup.string().required('*Rent deed start date is required'),
    rentDeedEndDate: Yup.string()
      .when(['rentDeedStartDate'], (rentDeedStartDate, schema) => {
        return rentDeedStartDate[0] === '' ?
          schema.test('start-check', '*Please select rent start date first', value => {
            return /.[]/.test(value);
          })
          :
          schema.required('*Rent deed end date is required')
      }),
    rentAgreementFile: Yup.string()
  });
};

export const AddExistingTenantsValidation = () => {
  return Yup.object({
    wingId: Yup.string().required('*Wing is required'),
    flatId: Yup.string().required('*Flat is required'),
    rentDeedStartDate: Yup.string().required('*Rent deed start date is required'),
    rentDeedEndDate: Yup.string()
      .when(['rentDeedStartDate'], (rentDeedStartDate, schema) => {
        return rentDeedStartDate[0] === '' ?
          schema.test('start-check', '*Please select rent start date first', value => {
            return /.[]/.test(value);
          })
          :
          schema.required('*Rent deed end date is required')
      }),
    rentAgreementFile: Yup.string().required('*Required')
  })
}

export const TenancyExpiryReminderValidation = () => {
  return Yup.object({
    days: Yup.string().required('*Days is required'),
    employeeIds: Yup.array(),
    committeeIds: Yup.array(),
  })
}

export const EditOccupantsValidation = () => {
  return Yup.object({
    prefix: Yup.string().required('*Required'),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    middleName: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only"),
    classMemberId: Yup.string().required('*Required'),
    titleId: Yup.string().required('*Required'),
    gender: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    secondaryMobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test({
            test: secondaryMobileNo => !secondaryMobileNo?.length,
            message: '*Please select country first'
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      }),
    mobileVisible: Yup.string(),
    sendeSMSOnAlternateNo: Yup.string(),
    landlineNo: Yup.string(),
    primaryEmail: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    secondaryEmail: Yup.string()
      .when({
        is: (val) => !!val?.length,
        then: () => Yup.string()
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address")
          .notOneOf([Yup.ref('primaryEmail'), null], '*Email should be different from primary'),
      }),
    emailVisible: Yup.string(),
    loginAccess: Yup.string(),
    sendeSMSOnAlternateEmail: Yup.string(),
    adharCardNo: Yup.string().test('not-only-zeros', '*Invalid identity proof number', value => {
      return !/^0/.test(value);
    }),
    adharFile: Yup.string(),
    panCardNo: Yup.string().min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        return !value || /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
      }),
    gstRegistrationNumber: Yup.string().test('not-only-zeros', '*Invalid account number', value => {
      return !/^0/.test(value);
    }),
    showInBill: Yup.string(),
    dob: Yup.date(),
    anniversaryDate: Yup.date(),
    profilePic: Yup.string(),
    flats: Yup.array()
      .of(
        Yup.object().shape({
          liveHereOrNot: Yup.string(),
          photoSubmitted: Yup.string(),
          dateOfpayment: Yup.date(),
          dateOfMembership: Yup.date(),
          dateofAdmission: Yup.date(),
          camstartDate: Yup.date(),
          age: Yup.number(),
          gstNo: Yup.string().test('not-only-zeros', '*Invalid account number', value => {
            return !/^0/.test(value);
          }),
          billingName: Yup.string(),
          customrFlatNo: Yup.string(),
          landlineNumber: Yup.string(),
          profession: Yup.string(),
          intercomNo: Yup.string(),
          fullAddress: Yup.string(),
          description: Yup.string(),
          noOfShareCertificate: Yup.string(),
          shareCertificateNo: Yup.string(),
          electricMeter: Yup.string(),
          saleDeedNo: Yup.string(),
          memberSecurityDeposit: Yup.string(),
          buildSecurityDeposit: Yup.string(),
        })
      )
  });
};

export const RenewFormValidation = () => {
  return Yup.object({
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    middleName: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only"),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    wingId: Yup.string().required('*Required'),
    flatId: Yup.string().required('*Required'),
    rentDeedStartDate: Yup.string().required('*Rent deed start date is required'),
    rentDeedEndDate: Yup.string()
      .when(['rentDeedStartDate'], (rentDeedStartDate, schema) => {
        return rentDeedStartDate[0] === '' ?
          schema.test('start-check', '*Please select rent start date first', value => {
            return /.[]/.test(value);
          })
          :
          schema.required('*Rent deed end date is required')
      }),
    rentAgreementFile: Yup.string(),
    email: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    alternateMobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test({
            test: alternateMobileNo => !alternateMobileNo?.length,
            message: '*Please select country first'
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      }),
    adharCardNo: Yup.string().required('*Required').test('not-only-zeros', '*Invalid identity proof number', value => {
      return /^[a-zA-Z0-9]{0,12}$/.test(value);
    }),
    panCardNo: Yup.string().required('*Required').min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
      }),
  });
};

export const MortgageValidation = () => {
  return Yup.object({
    bankName: Yup.string().required('*Required'),
    lienLetter: Yup.string().required('*Required'),
    lienReceipt: Yup.string().required('*Required'),
    dateLienCancellation: Yup.string().required('*Required'),
    cancellationDetails: Yup.string().required('*Required'),
    mcMeeting: Yup.string().required('*Required'),
    resolutionRef: Yup.string().required('*Required'),
    remark: Yup.string(),
    letterOfLien: Yup.string(),
    cancellationLetter: Yup.string().required('*Required'),
    societyNoc: Yup.string(),
  });
};

export const FamilyMemberValidation = () => {
  return Yup.object({
    familyMembers: Yup.array().of(
      Yup.object().shape({
        isFamilyLogin: Yup.string(),
        role: Yup.string().required("*Role is required"),
        name: Yup.string().required("*Name is required"),
        age: Yup.string().required("*Age is required"),
        emailAddress: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
        gender: Yup.string().required("*Gender is required"),
        relationship: Yup.string(),
        mobileCountryCode: Yup.string().default('').required('*Required'),
        countryISO: Yup.string().default('').required('*Required'),
        mobileNo: Yup.string()
          .when(['countryISO'], (countryISO, schema) => {
            return countryISO[0] === '' ?
              schema.test('country-code-check', '*Please select country first', value => {
                return /.[]/.test(value);
              })
              :
              schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
                return !/^0/.test(value);
              })
          })
          .required("Mobile number is required"),
        profession: Yup.string(),
        dateOfBirth: Yup.date().required("*Dob is required"),
        anniversaryDate: Yup.date(),
        bloodGroup: Yup.string(),
        otherDetails: Yup.string(),
      })
    )
  });
};

export const VehicleValidation = () => {
  return Yup.object().shape({
    vehicles: Yup.array()
      .of(
        Yup.object().shape({
          stickerNo: Yup.string().required('*Required'),
          parkingType: Yup.string().required('*Required'),
          parkingNo: Yup.string().required('*Required'),
          location: Yup.string().required('*Required'),
          vehicleType: Yup.string().required('*Required'),
          vehicleNo: Yup.string().required('*Required').test('not-only-zeros', '*Invalid Vehicle Number', value => {
            return /^[A-Z]{2}\s\d{2}\s[A-Z]{1,2}\s\d{4}$/.test(value);
          }),
          vehicleBrand: Yup.string().required('*Required'),
          modal: Yup.string().required('*Required'),
          year: Yup.string().required('*Required'),
          // rfid: Yup.string().required('*Required'),
        })
      )
  });
};
export const BulkAddVehicleValidation = () => {
  return Yup.object().shape({
    vehicles: Yup.array()
      .of(
        Yup.object().shape({
          flatId: Yup.string().required('*Required'),
          wingId: Yup.string().required('*Required'),
          stickerNo: Yup.string().required('*Required'),
          parkingType: Yup.string().required('*Required'),
          parkingNo: Yup.string().required('*Required'),
          location: Yup.string().required('*Required'),
          vehicleType: Yup.string().required('*Required'),
          vehicleNo: Yup.string().required('*Required').test('not-only-zeros', '*Invalid Vehicle Number', value => {
            return /^[A-Z]{2}\s\d{2}\s[A-Z]{1,2}\s\d{4}$/.test(value);
          }),
          vehicleBrand: Yup.string().required('*Required'),
          modal: Yup.string().required('*Required'),
          year: Yup.string().required('*Required'),
        })
      )
  });
};


export const ApplyForTenancyValidation = () => {
  return Yup.object({
    tenantDetails: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
          contactNo: Yup.string().required("*Contact number is required"),
          email: Yup.string().required('*Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
          relative: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required("*Name is required").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
                relationship: Yup.string().required("*Relationship is required").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
              })
            ),
          identityProof: Yup.string().required('*Required'),
          panCard: Yup.string().required('*Required'),
          noc: Yup.string().required('*Required'),
          form11: Yup.string().required('*Required'),
          form29: Yup.string().required('*Required'),
        })
      )
      .checkUnique("contactNo", "*Contact number must be unique")
      .checkUnique("email", "*Email must be unique"),

    fromDate: Yup.date().required('*Required'),
    toDate: Yup.date().required('*Required'),
    cargoDate: Yup.date().required('*Required'),
    description: Yup.string().trim().required('*Required'),
    wingId: Yup.string().required('*Required'),
    flatId: Yup.string().required('*Required'),
    acceptTerms: Yup.string().required('*Please accept all terms and conditions')
  });
};

export const AddOnlineRegistrationFormForRentPaymentValidation = () => {
  return Yup.object({
    type: Yup.string(),
    firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
    wingId: Yup.number().required('*Required'),
    flatId: Yup.number().required('*Required'),
    dob: Yup.string().required('*Required'),
    gender: Yup.string().required('*Required'),
    mobileCountryCode: Yup.string().default('').required('*Required'),
    countryISO: Yup.string().default('').required('*Required'),
    mobileNo: Yup.string()
      .when(['countryISO'], (countryISO, schema) => {
        return countryISO[0] === '' ?
          schema.test('country-code-check', '*Please select country first', value => {
            return /.[]/.test(value);
          })
          :
          schema.phone(countryISO[0], '*Please enter valid number').test('not-only-zeros', '*Please enter valid number', value => {
            return !/^0/.test(value);
          })
      })
      .required("Mobile number is required"),
    email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/, "*Please enter valid email address"),
    rentPropertyAddress: Yup.string().trim().required('*Required'),
    city: Yup.string().trim().required('*Required'),
    pinCode: Yup.number().typeError('*Must be number').required('*Required'),
    rentAgreementFile: Yup.string(),
    panCardNo: Yup.string().required('*Required').min(10, '*PAN No must be of 10 digits')
      .test('not-only-zeros', '*Invalid PAN number', value => {
        return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
      }),
    gstRegistrationNo: Yup.string().test('not-only-zeros', '*Invalid account number', value => {
      return !/^0/.test(value);
    }),
    rentAmount: Yup.string().required('*Required'),
    frequencyOfRent: Yup.string().required('*Required'),
    mothlyDueDate: Yup.string().required('*Required'),
    rentAgreementExpiryDate: Yup.string().required('*Required'),

    landLordDetails: Yup.object().shape({
      firstName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
      lastName: Yup.string().required('*Required').matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi, "*Please enter letters only").min(3, '*Must contain min 3 letters'),
      panCardNo: Yup.string().required('*Required').min(10, '*PAN No must be of 10 digits')
        .test('not-only-zeros', '*Invalid PAN number', value => {
          return /[a-zA-Z]{5}\d{4}[a-zA-Z]{1}/.test(value);
        }),
      gstRegistrationNo: Yup.string().test('not-only-zeros', '*Invalid account number', value => {
        return !/^0/.test(value);
      }),
      bankName: Yup.string().trim().required('*Required'),
      accountNumber: Yup.string().required('*Required').test('not-only-zeros', '*Invalid account number', value => {
        return !/^0/.test(value);
      })
        .min(11, 'Bank Number must be atleast of 11 digits'),
      ifscCode: Yup.string().required('*Required').test('not-only-zeros', '*Invalid account number', value => {
        return !/^0/.test(value)
      })
    })
  })
}


export const FlatDocumentValidation = () => {
  return Yup.object().shape({
    saleDeed: Yup.mixed().test('check-size', 'File size should not be more than 2MB', checkFileSizeLessThan2),
    noc: Yup.mixed().test('check-size', 'File size should not be more than 2MB', checkFileSizeLessThan2),
    rentAgreement: Yup.mixed().test('check-size', 'File size should not be more than 2MB', checkFileSizeLessThan2),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required("*Required"),
          doc: Yup.mixed().test('check-size', 'File size should not be more than 2MB', checkFileSizeLessThan2).required("*Required"),
        })
      )
  });
};


export const NomineeValidation = () => {
  return Yup.object().shape({
    nominees: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("*Required"),
          address: Yup.string(),
          relationship: Yup.string(),
          share: Yup.string().required("*Required").test("not-only-zeros", "*Share % can not be 0", (value) => {
            return !/^0/.test(value);
          }),
          dob: Yup.string(),
          dateOfNomination: Yup.string(),
          dateOfMC: Yup.string(),
          remarks: Yup.string(),
        })
      )
      .required("*Required"),
  });
};